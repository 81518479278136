import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {useEffect} from "react";
import axios from "axios";
import {sleep} from "../../helpers/tools";
import Snackbar from "@mui/material/Snackbar";
import {useState} from "react";
import moment from "moment";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function PhysicalEventModal({open, setOpen, physicalEvent}) {
    const handleClose = () => {
        setOpen(false)
    }

    const physicalEventAttendee = (physicalEvent) => {
        let query = 'https://public-front-api.therunningcollective.fr/attendee-physical-event?eventID=' + physicalEvent.id
        axios.post(query, null, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,}
        ).then((res) => {
            setIsGameOK(true)
            sleep(2000)
            window.location.assign(process.env.PUBLIC_URL + "/physical-event/"+ physicalEvent. slug)
            //window.location.reload()
        }).catch((err) => {
            console.log(err);
        })
    }

    const [isGameOK, setIsGameOK] = useState(false)
    const [date, setDate] = useState('')

    useEffect(() => {
        if (physicalEvent) {
            var beginDay = new Date(physicalEvent.date).getDate()
            var endDateFormat = moment(physicalEvent.date);
            var endMonthStr = endDateFormat.locale("fr").format('MMM')
            setDate(beginDay + " " + endMonthStr + " " + endDateFormat.year())
        }
    }, [physicalEvent])

    const handleCloseSnack = () => {
        setIsGameOK(false);
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical : 'top', horizontal : 'center' }}
                open={isGameOK}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                message={"Votre inscription a bien été prise en compte !"}
                key={'top' + 'center'}
                style={{marginTop: "50px"}}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:"center", lineHeight: "24px"}}>
                        Êtes-vous sûr de vouloir participer à cet évènement ?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:"14px", textAlign:"center"}}>
                        {physicalEvent.name}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:"14px", textAlign:"center"}}>
                        {physicalEvent.town}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:"14px", textAlign:"center"}}>
                        {date}
                    </Typography>
                    <Row style={{marginTop: "30px", textAlign:"center"}}>
                        <Col>
                            <button
                                onClick={() => handleClose()}
                                style={{
                                    backgroundColor: "rgb(203, 200, 200)",
                                    color: "black",
                                    borderRadius:'5px',
                                    padding: "5px 40px",
                                    border: "none",
                                    fontSize: "15px",
                                    fontWeight: 600
                                }}>
                                Annuler
                            </button>
                        </Col>
                        <Col>
                            <button
                                onClick={() => physicalEventAttendee(physicalEvent)}
                                style={{
                                    backgroundColor: "black",
                                    color: "white",
                                    borderRadius:'5px',
                                    padding: "5px 40px",
                                    border: "none",
                                    fontSize: "15px",
                                    fontWeight: 600
                                }}>
                                Valider
                            </button>
                        </Col>
                    </Row>

                </Box>
            </Modal>
        </div>
    );
}

PhysicalEventModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    physicalEvent: PropTypes.any,
};

export default PhysicalEventModal;