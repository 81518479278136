import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {sleep} from "../../helpers/tools";
import ReactGA from "react-ga4";
import {GetEmailUserData} from "./NewsletterPriceModal";
import {useTranslation} from "react-i18next";

function NewsletterProductModal(props) {
  const { product } = props;
  const { selectedProductDeclination } = props;
  const { unavailableSizes } = props

  useEffect(() => {
    ReactGA.event({
      category: 'Modal',
      action: 'Open modal /newsletter/product-newsletter'});
  }, [])

  const [selectedProductSize, setSelectedProductSize] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [productNewsletter, setProductNewsletter] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [succesMessage, setSuccessMessage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setProductNewsletter({
      productID: product.id,
      productName: product.name,
      productBrand: product.brand,
      productSlug: product.shortURL,
      declinationReference: selectedProductDeclination.reference,
      declinationImg: selectedProductDeclination.firstImg,
      researchedSize: selectedProductSize,
      clientEmail: emailAddress,
    })
  }, [emailAddress, selectedProductSize, product, selectedProductDeclination])

  function handleClose(productNewsletter){
    if (selectedProductSize === "") {
      setErrorMessage("Veuillez sélectionner une taille.")
    } else {
      //eslint-disable-next-line
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if ( re.test(emailAddress)) {
        fetch('https://public-front-api.therunningcollective.fr/product-newsletter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(productNewsletter)
        }).then(() => {
              ReactGA.event({
                category: 'Newsletter',
                action: 'Looking for ' + product.name + 'in size : ' + selectedProductSize
              });
              setSuccessMessage("Votre inscription a bien été enregistrée.");
              GetEmailUserData(emailAddress)
              sleep(2000).then(() => {
                document.location.reload()
              });
        }
        )
        .catch( err =>
            console.log(err)
        )
      } else {
        setErrorMessage("Veuillez entrer une adresse email valide.")
      }

    }
  }

  return (
    <Fragment>
      <Modal
        size={"sm"}
        show={props.show}
        onHide={props.onHide}
        className="product-quickview-modal-wrapper"
      >
        <Modal.Header closeButton><h3>{t('findmysize')}</h3></Modal.Header>
        <div className="modal-body">
          {succesMessage !== "" ?
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <span>{succesMessage}</span>
                </div>
              </div>
              :
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <p>{t('choseyoursize')}</p>
                  </div>
                  <br/>
                  <div>
                    <div className="pro-details-size col-md-6 col-sm-6 col-xs-12">
                      <span>{t('researchsize')} </span>
                      <div className="pro-details-size-content">
                        {
                          unavailableSizes && unavailableSizes.length > 0 ?
                              unavailableSizes.map((singleSize, key) => {
                                return (
                                    <label
                                        className={`pro-details-size-content--single`}
                                        key={key}
                                    >
                                      <input
                                          type="radio"
                                          value={singleSize}
                                          checked={
                                            singleSize === selectedProductSize
                                                ? "checked"
                                                : ""
                                          }
                                          onChange={() => {
                                            setSelectedProductSize(singleSize);
                                          }}

                                      />
                                      <span className="size-name">{singleSize}</span>
                                    </label>
                                );
                              })
                              : null
                        }

                      </div>
                    </div>
                    <br/>
                    <div className="pro-details-size col-md-6 col-sm-6 col-xs-12">
                      <span>{t('email')}</span>
                      <div>
                        <input
                            type="email"
                            name="user-email"
                            placeholder="Email"
                            onChange={(e) => setEmailAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <br/>
                    <div className="pro-details-quality col-md-6 col-sm-6 col-xs-12">
                      <div className="pro-details-cart btn-hover ml-0">
                        {errorMessage && errorMessage !== ""
                            ?
                            <div>
                              {errorMessage}
                            </div>
                            : null}
                        <button
                            rel="noopener noreferrer"
                            onClick={() => handleClose(productNewsletter)}
                        >
                          {t('purchase')}
                        </button>

                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <p>
                        {t('conditionewsletter1')}
                      </p>
                    </div>
                    <br/>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <p>{t('conditionewsletter2')}</p>
                    </div>
                  </div>
                </div>
              </div>
          }

        </div>
      </Modal>
    </Fragment>
  );
}



NewsletterProductModal.propTypes = {
  unavailableSizes: PropTypes.array,
  onHide: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    cartitems: state.cartData
  };
};

export default connect(mapStateToProps)(NewsletterProductModal);
