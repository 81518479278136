import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ReactGA from "react-ga4";
import Card from "react-bootstrap/Card";
import {Button, Col, Nav, OverlayTrigger, Row, Tab} from "react-bootstrap";
import axios from "axios";
import {GetImprovedUserData} from "./MyAccount";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Countdown from "react-countdown";
import {sleep} from "../../helpers/tools";
import ToggleSwitch from "../../components/ToogleButton";
import {isDesktop, isMobileOnly, isTablet} from "react-device-detect";
import {useTranslation} from "react-i18next";
import moment from "moment";
import { Divider, Tooltip } from "@mui/material";
import { BsSendCheck } from "react-icons/bs";
import { IoIosLink } from "react-icons/io";
import ExportProductsModal from "../../components/header/ExportProductsModal";
import { FaLink, FaRegCopy } from "react-icons/fa";
import GenerateAmbassadorLinkModal from "../../components/header/GenerateAmbassadorLinkModal";
import { formatDate } from "../shop-product/ProductTabRight";
import AmbassadorHistory from "../../components/AmbassadorHistory";

const ProductsAppointments = ({ location }) => {
  const { pathname } = location;
    const { i18n } = useTranslation()
    const [origin, setOrigin] = useState("fr")
    const [openExportProductsModal, setOpenExportProductsModal] = useState(false)
    const [currentSelectedProducts, setcurrentSelectedProducts] = useState(null)
    const [currentData, setCurrentData] = useState({})
    const [openGenerateLink, setOpenGenerateLink] = useState(false)
    const [currentSelectedProductsLink, setcurrentSelectedProductsLink] = useState(null)
    const [currentLink, setCurrentLink] = useState(null);
    const [friendReferred, setFriendReferred] = useState(null);
    const [loading, setLoading] = useState(null);
    const [countOfReporting, setCountOfReporting] = useState(null);
    const [historyPeriod, setHistoryPeriod] = useState('month');

    useEffect(() => {
      if(historyPeriod){
        let start = getHistoryPeriod(historyPeriod);
        let end = new Date()
        fetchHistoryPrice(start, end);
      }
      
    }, [historyPeriod]);

    const getHistoryPeriod = (historyPeriod) => {
      let d = new Date();

      switch (historyPeriod) {
          case 'year':
              return new Date(new Date().getFullYear(), 0, 1);
          case 'month':
              return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
          case 'week':
              return getMonday(new Date());
      }

      return d;
    }

  const fetchHistoryPrice = (beginDate, endDate) => {
      let query = 'https://public-front-api.therunningcollective.fr/ambassador-history?beginDate='+ formatDate(beginDate)+'&endDate='+formatDate(endDate)
      axios
          .get(query,{
            headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,}
          )
          .then((res) => {
              if(res.data){
                  setCountOfReporting(res.data);
              } else {
                  setCountOfReporting([]);
              }
          })
          .catch((err) => {
              console.log(err);
          })
  };

    const getFriendReferred = () => {
        let query = 'https://public-front-api.therunningcollective.fr/friend-referred'
        axios.put(query, null, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,}
        )
            .then((res) => {
                setFriendReferred(res.data)
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
            })
    }

    useEffect(() => {
      getFriendReferred();
      setOrigin(i18n.language)
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [])

  const buildData = (appointment) => {
    setCurrentData({
      email:  appointment && appointment.email ? appointment.email : "",
      id: appointment && appointment.id ? appointment.id : "",
    })
  }

  const [newProductsAppointments, setNewProductsAppointments] = useState(true);
  const [displayedProductsAppointments, setDiplayedProductsAppointments] = useState({});

  const getProductsAppointments = (newProductsAppointments) => {
    ReactGA.event({
      category: 'ProductsAppointments',
      action: 'Get data'
    });

    let query = 'https://public-front-api.therunningcollective.fr/export-products'    
    axios.get(query, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
      }
      ,})
      .then((res) => {
        setDiplayedProductsAppointments(res.data ? res.data.reverse() : [])
      })
      .catch((err) => {
          console.log(err);
      })
  };

  useEffect(() => {
    getProductsAppointments(newProductsAppointments);
  }, [newProductsAppointments]);

  const rendererSoon = ({ days, hours, minutes, seconds, completed }) => {
    return <span style={{color: "red", fontWeight: 500, fontSize: "13px"}}>Expire dans {days}j {hours}h {minutes}mn</span>;
  };

  const onGameChange = (checked) => {
      setNewProductsAppointments(checked);
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Mes sélections de produits</title>
        <meta
          name="description"
          content="Retrouvez ici tous les jeux concours disponibles"
        />
        <meta name="robots" content="noindex, nofollow"/>
      </MetaTags>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Accueil</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Mes sélections de produits
      </BreadcrumbsItem>
      <LayoutOne
          headerContainerClass="container-fluid"
          headerPaddingClass="header-padding-2"
          headerTop="visible"
      location={location}>
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="error-area pt-20 pb-20">
          <div className="container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="my-reporting">
            <Row>
              <Col xs={isMobileOnly ? 12 : 4}>
                <h1 className="h3 mb-3">Mon espace ambassadeur</h1>
              </Col>
              <Col xs={isMobileOnly ? 12 : 4}>
              {
                isMobileOnly ?
                <div onClick={() => {setOpenGenerateLink(!openGenerateLink); setCurrentLink(friendReferred.link)}} style={{textAlign: "center", cursor: "pointer", padding : "10px 15px", color: "white", borderRadius: "5px", backgroundColor: "black", marginBottom: "10px"}}>Voir mon lien ambassadeur<FaRegCopy style={{paddingLeft: "10px"}} size={"1.8rem"}/></div>
                : 
                <span onClick={() => {setOpenGenerateLink(!openGenerateLink); setCurrentLink(friendReferred.link)}} style={{textAlign: "center", cursor: "pointer", padding : "10px 15px", color: "white", borderRadius: "5px", backgroundColor: "black", marginBottom: "10px"}}>Voir mon lien ambassadeur<FaRegCopy style={{paddingLeft: "10px"}} size={"1.8rem"}/></span>
              }
              </Col>
              <Col xs={isMobileOnly ? 12 : 4}>
              {
                isMobileOnly ?
                <div onClick={() => {setOpenGenerateLink(!openGenerateLink); setCurrentLink(null)}} style={{cursor: "pointer", padding : "10px 15px", color: "white", borderRadius: "5px", backgroundColor: "gray", textAlign: "center"}}>Générer un lien<IoIosLink style={{paddingLeft: "10px"}} size={"1.8rem"}/></div>
                : 
                <span onClick={() => {setOpenGenerateLink(!openGenerateLink); setCurrentLink(null)}} style={{cursor: "pointer", padding : "10px 15px", color: "white", borderRadius: "5px", backgroundColor: "gray"}}>Générer un lien<IoIosLink style={{paddingLeft: "10px"}} size={"1.8rem"}/></span>
              }
              </Col>
            </Row>
              <Row style={{marginTop: isMobileOnly ? "20px" : ""}}>
                <Col md="5" xl="3">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="my-reporting">Mon reporting</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="my-link">Mes liens</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col md="7" xl="9">
                <Tab.Content>
              <Tab.Pane eventKey="my-reporting">
                {
                  countOfReporting && countOfReporting.length >= 0 ? 
                  <AmbassadorHistory historyPrices={countOfReporting} setHistoryPeriod={setHistoryPeriod} historyPeriod={historyPeriod}/>
                  : null 
                }
              </Tab.Pane>
              <Tab.Pane eventKey="my-link">
                <Row>
                  {displayedProductsAppointments && displayedProductsAppointments.length > 0 ?
                    displayedProductsAppointments.map(appointment => {
                      return (
                          <Col xs={isMobileOnly ? 12 : 4} style={{textAlign: 'center', marginTop: "20px"}}>
                              <Card style={{ width: '100%' }}>
                                  <Card.Img variant="top" src= {appointment.cover}
                                  style={{width: '100%'}}/>
                                  {
                                      appointment.productImg != "" ?
                                        <img style={{width:"100%"}} src={appointment.productImg != "" ? appointment.productImg : "https://img.therunningcollective.fr/shoes-background-1-jpeg-jpg-jpg"}/>
                                      : 
                                      <div>
                                          <img style={{width:"100%", position: "absolute", zIndex: "10"}} src={appointment.productImg != "" ? "https://img.therunningcollective.fr/" + appointment.productImg : "https://img.therunningcollective.fr/shoes-background-1-jpeg-jpg-jpg"}/>
                                          <img style={{maxWidth:"50px", position: "relative", zIndex: "20", marginLeft: "35px", marginTop: "35px", borderRadius: "10px"}} src={appointment.physicalShopImg != "" ? "https://img.therunningcollective.fr/" + appointment.physicalShopImg : "https://img.therunningcollective.fr/shoes-background-1-jpeg-jpg-jpg"}/>                                                       
                                      </div>
                                  }

                                  <Card.Body>
                                  <Card.Title>
                                    {moment(appointment.createdDate).format("DD/MM/YYYY").toString()} à {moment(appointment.createdDate).hours()}h{moment(appointment.createdDate).minutes() != 0 ? moment(appointment.createdDate).minutes() < 10 ? "0" + moment(appointment.createdDate).minutes(): moment(appointment.createdDate).minutes() : "00"}
                                  </Card.Title>
                                  <Card.Text>
                                    <div style={{fontSize:"13px"}}>{appointment.email}</div>
                                    <Divider style={{marginBottom: "10px", marginTop: "5px"}}/>
                                    {
                                      appointment.products && appointment.products.length > 0 && appointment.products.map(product => {
                                        return(
                                          <Row style={{paddingBottom: "5px"}}>
                                            <Col xs={4}>
                                              <img src={product.firstImageUrl} style={{width: "100%"}}/>
                                            </Col>
                                            <Col xs={8}>
                                              <div>{product.name}</div>
                                            </Col>
                                          </Row>
                                        )
                                      })
                                    }
                                    <Divider style={{marginBottom: "10px", marginTop: "5px"}}/>
                                    <div style={{textAlign: "center"}}>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip">Renvoyer la sélection par e-mail</Tooltip>}
                                      >
                                        <BsSendCheck size={isMobileOnly ? "1.8rem": "1.2rem"} style={{cursor: "pointer", marginRight: "20px"}} onClick={() => {
                                          setcurrentSelectedProducts(appointment.products);
                                          setcurrentSelectedProductsLink(appointment.link)
                                          setOpenExportProductsModal(true);
                                          buildData(appointment);
                                        }}/>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip">Accéder à la sélection de produits</Tooltip>}
                                      >
                                        <a href={appointment.link} target="_blank"><IoIosLink size={isMobileOnly ? "1.8rem": "1.3rem"} style={{cursor: "pointer"}}/></a>
                                      </OverlayTrigger>
                                    </div>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                          </Col>
                      )
                    })
                    :
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                        <span>Aucun historique disponible... :)</span>
                    </div>}
                </Row>
              </Tab.Pane>
          </Tab.Content>
                </Col>
              </Row>
          </Tab.Container>
          
            {
                openGenerateLink ?
                    <GenerateAmbassadorLinkModal currentQuery={currentData} open={openGenerateLink} setOpen={setOpenGenerateLink} currentLink={currentLink}/>
                : null
            }
            {
                openExportProductsModal ?
                    <ExportProductsModal link={currentSelectedProductsLink} currentQuery={currentData} open={openExportProductsModal} setOpen={setOpenExportProductsModal} products={currentSelectedProducts}/>
                : null
            }
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

ProductsAppointments.propTypes = {
  location: PropTypes.object
};

export default ProductsAppointments;

function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
  diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}