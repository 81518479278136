import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Col, Row, Spinner} from "react-bootstrap";
import PropTypes from "prop-types";
import SnackbarCredits from "../Snackbar-credits";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {sleep} from "../../helpers/tools";
import Snackbar from "@mui/material/Snackbar";
import {useState} from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    zIndex: 10000,
};

function AmbassadorRewardModal({open, setOpen, reward, rewardGender, rewardBrand, rewardCover, rewardPrice, rewardName, rewardSize, rewardRef}) {
    const [address, setAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [town, setTown] = useState('');
    const [country, setCountry] = useState('');

    const [errorAddress, setErrorAddress] = useState(null);
    const [errorZipCode, setErrorZipCode] = useState(null);
    const [errorTown, setErrorTown] = useState(null);
    const [errorCountry, setErrorCountry] = useState(null);

    const handleClose = () => {
        setOpen(false)
    }

    const rewardAttendee = (reward) => {
        reward.price = Number(rewardPrice)
        reward.name = rewardName
        reward.isAmbassadorReward = true
        reward.cover = rewardCover
        if(reward && reward.uniqueProduct){
            reward.description = "\nProduit : " +rewardName + "\n" + "Sexe : " + rewardGender + "\n" + "Taille : " + rewardSize + "\n" + "Prix : " + rewardPrice + " TRCoins\n"
        } else {
            reward.description = "\nMarque : " + rewardBrand + "\n" + "Produit : " +rewardName + "\n" + "Référence : " + rewardRef + "\n" + "Sexe : " + rewardGender + "\n" + "Taille : " + rewardSize + "\n" + "Prix : " + rewardPrice + " TRCoins\n"
        }
        reward.bigDescription = ""
        if (reward.giftToPhysicalSend){
            if (address === ""){
                setErrorAddress("Veuillez écrire votre addresse.")
            } else {
                reward.address = address
                setErrorAddress(null)
            }
            if (zipCode === ""){
                setErrorZipCode("Veuillez écrire votre code postal.")
            } else {
                reward.zipCode = zipCode
                setErrorZipCode(null)
            }
            if (town === ""){
                setErrorTown("Veuillez écrire votre ville.")
            } else {
                reward.town = town
                setErrorTown(null)
            }
            if (country === ""){
                setErrorCountry("Veuillez écrire votre pays.")
            } else {
                reward.country = country
                setErrorCountry(null)
            }
        }

        if (!reward.giftToPhysicalSend || (reward.giftToPhysicalSend && (address != "" && zipCode != "" && country != "" && town !== ""))){
            setIsOK(true)
            let query = 'https://public-front-api.therunningcollective.fr/attendee-reward'
            axios.post(query, reward, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
                }
                ,}
            ).then((res) => {
                setIsGameOK(true)
                sleep(2000)
                window.location.assign(process.env.PUBLIC_URL + "/my-rewards")
                //window.location.reload()
            }).catch((err) => {
                setIsOK(false)
                setErrorCountry("Une erreur s'est produite, veuillez réessayer")
                console.log(err);
            })
        }
    }

    const [isGameOK, setIsGameOK] = useState(false)
    const [isOK, setIsOK] = useState(false)

    const handleCloseSnack = () => {
        setIsGameOK(false);
    };

    return (
        <div style={{zIndex:"2147483658"}}>
            <Snackbar
                anchorOrigin={{ vertical : 'top', horizontal : 'center' }}
                open={isGameOK}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                message={"Votre récompense vous attend dans votre profil !"}
                key={'top' + 'center'}
                style={{marginTop: "50px"}}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:"center"}}>
                        Obtenir cette récompense ?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:"12px", textAlign:"center"}}>
                        Nom : {rewardName}<br/>
                        {
                            reward && reward.uniqueProduct ? null :
                                <>Référence : {rewardRef}<br/></>
                            }
                        Taille : {rewardSize}<br/>
                    </Typography>
                    {
                        reward.giftToPhysicalSend ?
                        <div>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:"15px", fontWeight: 'bold', textAlign:"left"}}>
                            Nous avons besoin des informations suivantes pour vous expédier la récompense :
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleClose} sx={{mt: 3}}>
                            <Grid container spacing={2}></Grid>
                                <Grid item xs={12} sm={6} style={{marginBottom: "5px"}}>
                                {
                                    errorAddress ?
                                        <TextField
                                            error
                                            name="address"
                                            required
                                            fullWidth
                                            id="address"
                                            label="Adresse"
                                            onChange={(event => {
                                                setAddress(event.target.value)
                                            })}
                                            helperText={errorAddress}
                                        />
                                        :
                                        <TextField
                                            name="address"
                                            required
                                            fullWidth
                                            id="address"
                                            label="Adresse"
                                            onChange={(event => {
                                                setAddress(event.target.value)
                                            })}
                                        />
                                }
                             </Grid>
                             <Grid item xs={12} sm={6} style={{marginBottom: "5px"}}>
                                {
                                    errorZipCode ?
                                        <TextField
                                            error
                                            name="zipCode"
                                            required
                                            fullWidth
                                            id="zipCode"
                                            label="Code Postal"
                                            onChange={(event => {
                                                setZipCode(event.target.value)
                                            })}
                                            helperText={errorZipCode}
                                        />
                                        :
                                        <TextField
                                            name="zipCode"
                                            required
                                            fullWidth
                                            id="zipCode"
                                            label="Code Postal"
                                            onChange={(event => {
                                                setZipCode(event.target.value)
                                            })}
                                        />
                                }
                             </Grid>
                             <Grid item xs={12} sm={6} style={{marginBottom: "5px"}}>
                                {
                                    errorTown ?
                                        <TextField
                                            error
                                            name="town"
                                            required
                                            fullWidth
                                            id="town"
                                            label="Ville"
                                            onChange={(event => {
                                                setTown(event.target.value)
                                            })}
                                            helperText={errorTown}
                                        />
                                        :
                                        <TextField
                                            name="town"
                                            required
                                            fullWidth
                                            id="town"
                                            label="Ville"
                                            onChange={(event => {
                                                setTown(event.target.value)
                                            })}
                                        />
                                }
                             </Grid>
                             <Grid item xs={12} sm={6} style={{marginBottom: "5px"}}>
                                {
                                    errorCountry ?
                                        <TextField
                                            error
                                            name="country"
                                            required
                                            fullWidth
                                            id="country"
                                            label="Pays"
                                            onChange={(event => {
                                                setCountry(event.target.value)
                                            })}
                                            helperText={errorCountry}
                                        />
                                        :
                                        <TextField
                                            name="country"
                                            required
                                            fullWidth
                                            id="country"
                                            label="Pays"
                                            onChange={(event => {
                                                setCountry(event.target.value)
                                            })}
                                        />
                                }
                             </Grid>
                         </Box>
                                </div>
                                : null
                            }
                            
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Je valide l'échange de {rewardPrice} crédits contre cette récompense.
                    </Typography>
                            {
                                isOK ?
                                    <Row style={{marginTop: "30px", textAlign:"center"}}>
                                        <Col>
                                            <div style={{textAlign: 'center'}}>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden"></span>
                                                </Spinner>
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <Row style={{marginTop: "30px", textAlign:"center"}}>
                                    <Col>
                                        <button
                                            onClick={() => handleClose()}
                                            style={{
                                                backgroundColor: "rgb(203, 200, 200)",
                                                color: "black",
                                                borderRadius:'15px',
                                                padding: "10px 20px",
                                                border: "none",
                                                fontSize: "15px",
                                                fontWeight: 600
                                            }}>
                                            Annuler
                                        </button>
                                    </Col>
                                    <Col>
                                    <button
                                        onClick={() => rewardAttendee(reward)}
                                        style={{
                                            backgroundColor: "black",
                                            color: "white",
                                            borderRadius:'15px',
                                            padding: "10px 20px",
                                            border: "none",
                                            fontSize: "15px",
                                            fontWeight: 600
                                        }}>
                                        Echanger
                                    </button>
                                </Col>
                                    </Row>
                            }
                </Box>
            </Modal>
        </div>
    );
}

AmbassadorRewardModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    reward: PropTypes.any,
};

export default AmbassadorRewardModal;