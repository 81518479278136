import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Swiper from "react-id-swiper";
import SectionTitle from "../../components/section-title/SectionTitle";
import ProductGrid from "./ProductGrid";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {isMobileOnly} from "react-device-detect";

const RelatedApparelSlider = ({ spaceBottomClass, brand, shopType, product, headCategory, origin }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const fetchData = React.useCallback(() => {
    if(origin && origin == "be"){
      origin = "fr"
    }
    // your function using screenSize here
    let query = 'https://public-front-api.therunningcollective.fr/products?headCategory=/products/apparel&lang='+origin+'&shopType='+shopType

    axios
        .post(query)
        .then((res) => {
          if (res.data.products.length > 0){
            let result = res.data.products
            let genderResult = result.filter(p => p.gender.includes(product.gender) || product.gender === "Unisex")
            let filteredData = genderResult
            if (headCategory == "/products/shoes" || headCategory == "/products/spikes" || headCategory == "/products/apparel") {
              let testFilteredData = genderResult.filter(product => {return product.brand === brand})
              let testFilteredDataWithoutAccessories = testFilteredData.filter(product => {return product.headCategory !== "/products/accessories"})
              let nbOfOtherProducts = testFilteredDataWithoutAccessories.filter(product => {return product.headCategory !== headCategory}).length
              if (nbOfOtherProducts != 0) {
                filteredData = genderResult.filter(product => {return product.brand === brand})
              }
            }

            filteredData = filteredData.filter(p => p.id !== product.id)
            filteredData = filteredData.filter(p => p.calculatedPrice > 0)

            let newData = []
            if (headCategory == "/products/shoes" || headCategory == "/products/spikes") {
              let apparelProducts = filteredData.filter(p => p.headCategory === "/products/apparel")
              let tsProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Tee-shirts")[0])
              let vesteProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Vestes")[0])
              let shortsProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Shorts")[0])
              let collantsProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Collants")[0])
              let cuissardsProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Cuissards")[0])
              let debardeursProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Débardeurs")[0])


              if (tsProducts.length > 0){
                newData.push(tsProducts[0])
              } else {
                tsProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Tee-shirts")[0])
                if (tsProducts.length > 0){
                  newData.push(tsProducts[0])
                }
              }
              if (debardeursProducts.length > 0){
                newData.push(debardeursProducts[0])
              } else {
                debardeursProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Débardeurs")[0])
                if (debardeursProducts.length > 0){
                  newData.push(debardeursProducts[0])
                }
              }
              if (vesteProducts.length > 0){
                newData.push(vesteProducts[0])
              } else {
                vesteProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Vestes")[0])
                if (vesteProducts.length > 0){
                  newData.push(vesteProducts[0])
                }
              }
              if (shortsProducts.length > 0){
                newData.push(shortsProducts[0])
              } else {
                shortsProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Shorts")[0])
                if (shortsProducts.length > 0){
                  newData.push(shortsProducts[0])
                }
              }
              if (collantsProducts.length > 0){
                newData.push(collantsProducts[0])
              } else {
                collantsProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Collants")[0])
                if (collantsProducts.length > 0){
                  newData.push(collantsProducts[0])
                }
              }
              if (cuissardsProducts.length > 0){
                newData.push(cuissardsProducts[0])
              } else {
                cuissardsProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Cuissards")[0])
                if (cuissardsProducts.length > 0){
                  newData.push(cuissardsProducts[0])
                }
              }
            }

            if (headCategory == "/products/apparel") {
              let shoesProducts = filteredData.filter(p => p.headCategory === "/products/shoes")
              let shopType
              if(product && product.shopType && product.shopType.length > 0){
                shopType = product.shopType[0]
              }
              if (shopType == "athletisme"){
                let raceProducts = shoesProducts.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Compétitions")[0])
                if (raceProducts.length > 0){
                  newData.push(raceProducts[0])
                }
                let trainingProducts = shoesProducts.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Entrainements")[0])
                if (trainingProducts.length > 0){
                  newData.push(trainingProducts[0])
                }
              } else if (shopType == "running"){
                let raceProducts = shoesProducts.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Compétitions" || c === "Neutre")[0])
                if (raceProducts.length > 0){
                  newData.push(raceProducts[0])
                }
                let trainingProducts = shoesProducts.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Entrainements" || c === "Neutre")[0])
                if (trainingProducts.length > 0){
                  newData.push(trainingProducts[0])
                }
              } else if (shopType == "trail"){
                let raceProducts = shoesProducts.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Trail court")[0])
                if (raceProducts.length > 0){
                  newData.push(raceProducts[0])
                }
                let trainingProducts = shoesProducts.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Trail long")[0])
                if (trainingProducts.length > 0){
                  newData.push(trainingProducts[0])
                }
              }

              let apparelProducts = filteredData.filter(p => p.headCategory === "/products/apparel")
              let tsProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Tee-shirts")[0])
              let vesteProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Vestes")[0])
              let shortsProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Shorts")[0])
              let collantsProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Collants")[0])
              let cuissardsProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Cuissards")[0])
              let debardeursProducts = apparelProducts.filter(p =>
                  p && p.categories && p.categories.filter(c => c === "Débardeurs")[0])


              if (tsProducts.length > 0){
                newData.push(tsProducts[0])
              } else {
                tsProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Tee-shirts")[0])
                if (tsProducts.length > 0){
                  newData.push(tsProducts[0])
                }
              }
              if (debardeursProducts.length > 0){
                newData.push(debardeursProducts[0])
              } else {
                debardeursProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Débardeurs")[0])
                if (debardeursProducts.length > 0){
                  newData.push(debardeursProducts[0])
                }
              }
              if (vesteProducts.length > 0){
                newData.push(vesteProducts[0])
              } else {
                vesteProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Vestes")[0])
                if (vesteProducts.length > 0){
                  newData.push(vesteProducts[0])
                }
              }
              if (shortsProducts.length > 0){
                newData.push(shortsProducts[0])
              } else {
                shortsProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Shorts")[0])
                if (shortsProducts.length > 0){
                  newData.push(shortsProducts[0])
                }
              }
              if (collantsProducts.length > 0){
                newData.push(collantsProducts[0])
              } else {
                collantsProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Collants")[0])
                if (collantsProducts.length > 0){
                  newData.push(collantsProducts[0])
                }
              }
              if (cuissardsProducts.length > 0){
                newData.push(cuissardsProducts[0])
              } else {
                cuissardsProducts = genderResult.filter(p =>
                    p && p.categories && p.categories.filter(c => c === "Cuissards")[0])
                if (cuissardsProducts.length > 0){
                  newData.push(cuissardsProducts[0])
                }
              }
            }
            //newData.push(filteredData.filter(p => p.headCategory === "/products/nutrition")[0])

            setProducts(newData);
            setLoading(false)
          }
        })
        .catch((err) => {
          console.log(err);
        })
  }, [shopType, brand, product]);

  useEffect(() => {
    if (product && product.associatedProducts && product.associatedProducts.length){

    } else {
      fetchData(shopType);
    }
  }, [shopType, fetchData, product]);

  const settings = {
    loop: false,
    slidesPerView: 4,
    grabCursor: true,
    breakpoints: {
      1024: {
        slidesPerView: 2
      },
      768: {
        slidesPerView: 3
      },
      640: {
        slidesPerView: 2
      },
      320: {
        slidesPerView: 2
      }
    }
  };

  return (
    <div
      className={`related-product-area ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      {!loading ?
          products.length > 0 ?
              <div className="container-fluid" style={{paddingLeft: isMobileOnly ? "0px": "15px", paddingRight: isMobileOnly ? "0px": "15px"}}>
                  <SectionTitle
                      titleText={t('completestyle')}
                      positionClass="text-left"
                      spaceClass="mb-10 ml-15"
                  />
                  <div className={"row" + isMobileOnly ? " tabproducteleven" : ""}>
                    <Swiper {...settings}>
                      <ProductGrid
                          category={brand}
                          limit={6}
                          products={products}
                          sliderClassName="swiper-slide"
                      />
                    </Swiper>
                  </div>
                </div>
          : <span></span>

      :
          <div style={{textAlign: 'center'}}>
            <Spinner animation="border" role="status" >
              <span className="visually-hidden"></span>
            </Spinner>
          </div>}

    </div>
  );
};

RelatedApparelSlider.propTypes = {
  category: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default RelatedApparelSlider;
