import PropTypes from "prop-types";
import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {slugify} from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import ReactPixel from "react-facebook-pixel";
import {useTranslation} from "react-i18next";

const ProductGridRaceSingle = ({
  product,
  currency,
  addToWishlist,
  wishlistItem,
  sliderClassName,
  spaceBottomClass
}) => {
  const { addToast } = useToasts();
  const { t } = useTranslation()

  const finalProductPrice = product.price;
  const discountedPrice = product.bestPrice;
  const finalDiscountedPrice = +(
      discountedPrice * currency.currencyRate
  ).toFixed(2);

  const discount = Math.round((product.price - product.bestPrice) / product.price * 100)

  return (
    <Fragment>
      <div
        className={`col-xl-3 col-md-6 col-lg-4 col-sm-6 ${
          sliderClassName ? sliderClassName : ""
        }`}
      >
        <div
          className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}
          onClick={() =>
              ReactPixel.track('ViewContent', {
                content_name: product.name,
                content_type: 'product',
                content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                content_ids: product.id,
                value: finalDiscountedPrice !=0 ? finalDiscountedPrice : product.price,
                currency: 'EUR',
              })
          }
        >
          <div className="product-img">
            <Link to={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
              <img
                className="default-img"
                src={product.firstImageUrl}
                alt={product.name + " - au meilleur prix, pas chers"}
                style={{borderRadius: "15px", border: "1px solid lightgray"}}
              />
            </Link>
            {discount || product.new ? (
              <div className="product-img-badges">
                {discount > 0 && discount !== 100 ? (
                  <span className="purple">-{discount}%</span>
                ) : (
                  ""
                )}
                {product.new ? <span className="purple">New</span> : ""}
              </div>
            ) : (
              ""
            )}

            <div className="product-action">
              <div className="pro-same-action pro-wishlist">
                <button
                  className={wishlistItem !== undefined ? "active" : ""}
                  disabled={wishlistItem !== undefined}
                  title={
                    wishlistItem !== undefined
                      ? "Added to wishlist"
                      : "Add to wishlist"
                  }
                  onClick={() => addToWishlist(product, addToast)}
                >
                  <i className="pe-7s-like" />
                </button>
              </div>
            </div>
          </div>
          <div className="product-content text-center">
            <h3 style={{lineHeight: "20px", fontSize:"14px"}}>
              <Link to={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                {product.name}
              </Link>
            </h3>
            <div>
              <a href={process.env.PUBLIC_URL + "/product/" + product.shortURL}>
                <span style={{color: "grey", fontWeight: 500, fontSize: "13px"}}>{t(product.gender.toLowerCase())}</span>
              </a>
            </div>
            {product.rating && product.rating > 0 ? (
              <div className="product-rating">
                <Rating ratingValue={product.rating} />
              </div>
            ) : (
              ""
            )}
            <div className="product-price">
              {(discountedPrice !== null && finalDiscountedPrice > 0 && finalProductPrice !== finalDiscountedPrice) ? (
                <Fragment>
                  <span>{finalDiscountedPrice + currency.currencySymbol}</span>{" "}
                  <span className="old">
                    {finalProductPrice + currency.currencySymbol}
                  </span>
                </Fragment>
              ) : (
                <span>{finalProductPrice + currency.currencySymbol} </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ProductGridRaceSingle.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default ProductGridRaceSingle;
