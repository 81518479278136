import PropTypes from "prop-types";
import React, {forwardRef, Fragment, useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ReactGA from "react-ga4";
import axios from "axios";
import parse from "react-html-parser";
import moment from "moment";
import {Col, Row, Spinner} from "react-bootstrap";
import {
    BsCalendarMonth,
    BsClock,
    BsPinMap,
    BsStar,
    BsStarFill,
    CgWebsite,
    FaMountain, FaRunning,
    IoMdPricetags
} from "react-icons/all";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {BrowserView, isMobileOnly, isTablet} from 'react-device-detect';
import RelatedProductRaceSlider from "../../wrappers/product/RelatedProductRaceSlider";
import {OpenCapacitorSite} from "../../components/brand-logo/BrandLogoOneSingle";
import {apiConversionRequest, getClientTokenData, GetUserData} from "../../App";
import {Favorite} from "@material-ui/icons";
import Snackbar from "@mui/material/Snackbar";
import TokenSignInModal from "../../components/product/TokenSignInModal";
import {HeartBroken} from "@mui/icons-material";
import ReactPixel from "react-facebook-pixel";
import ShoesSizeUpdateModal from "../../components/header/ShoesSizeUpdateModal";
import PhysicalEventModal from "../../components/header/PhysicalEventModal";
import { getWithExpiry } from "../home/HomeFashionTwo";
import RewardModal from "../../components/header/RewardModal";

const Reward = ({location}) => {
    const {pathname} = location;
    let slug = location.pathname.split("/reward/").pop()
    const [reward, setEvent] = useState({})
    const [headerPadding, setHeaderPadding] = useState("15px")
    const [isSmallScreen, setSmallScreen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState('')
    const [openModalUpdateShoe, setOpenModalUpdateShoe] = useState(false)
    const [user, setUser] = useState(getWithExpiry('trc-front-user'));
    const [openModalAttendee, setOpenModalAttendee] = useState(false)
    const [loadingUser, setLoadingUser] = useState(false)
    const [openRewardValidModal, setOpenRewardValidModal] = useState(false);

    const getProfile = () => {
        setLoadingUser(true)
        let query = 'https://public-front-api.therunningcollective.fr/profile'
        axios.get(query, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
                }
                ,
            }
        )
            .then((res) => {
                setUser(res.data)
            })
            .finally(() => {
                setLoadingUser(false)
            })
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
        ReactPixel.pageView();
        ReactPixel.fbq('track', 'PageView');
        GetUserData().then(
            data => apiConversionRequest(data.query, "PageView")
        )
    }, [])

    useEffect(() => {
        if (reward) {
            var beginDay = new Date(reward.date).getDate()
            var endDateFormat = moment(reward.date);
            var endMonthStr = endDateFormat.locale("fr").format('MMM')
            setDate(beginDay + " " + endMonthStr + " " + endDateFormat.year())
        }
    }, [reward])

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        } else {
            setSmallScreen(false)
        }
    }, [])

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        } else {
            setSmallScreen(true)
        }
    })

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname});
        fetchData(slug)
        getProfile()
    }, [slug])

    Array.prototype.contains = function (element) {
        return this.indexOf(element) > -1;
    };

    const handleValideReward = () => {
        setOpenRewardValidModal(true)
    }

    const fetchData = (slug) => {
        let query = 'https://public-front-api.therunningcollective.fr/reward?id=' + slug
        axios.get(query, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            }
            ,})
            .then((res) => {
                console.log(res.data)
                setEvent(res.data)
                setLoading(false);
                window.prerenderReady = true
            })
            .catch((err) => {
                //window.location.href = "/404-not-found"
                console.log(err);
            })

    };

    return (
        <Fragment>
            <MetaTags>
                <title>{reward ? reward.name : ""}</title>
                <meta
                name="description"
                content={reward ? reward.longDescription : ""}
                />
                <meta name="robots" content="noindex, nofollow"/>
            </MetaTags>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Accueil</BreadcrumbsItem>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/my-rewards"}>
                Mes récompenses
            </BreadcrumbsItem>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
                {reward ? reward.name : ""}
            </BreadcrumbsItem>

            <LayoutOne headerTop="visible"
                       headerContainerClass="container-fluid"
                       headerPaddingClass="header-padding-2"
                       location={location}>
                {/* breadcrumb */}
                <Breadcrumb location={location} rewardParticipating={reward.isParticipating}/>
                <RewardModal open={openRewardValidModal} setOpen={setOpenRewardValidModal} reward={reward}/>

                <div className={isMobileOnly ? "" : "container"}>
                    {
                        reward && !loading ?
                            <div className="container-fluid" style={{paddingTop: headerPadding, paddingBottom: '40px'}}>
                                
                                <div style={{paddingTop: reward.name.length > 53 ? "40px" : reward.name.length > 25 ? "20px" : "10px"}}>
                                    <div style={{height: "200px", marginLeft: "-15px",
                                        marginRight: "-15px",
                                        marginTop: "-14px"}}>
                                        <div style={{height: "100%",
                                            border: reward.isParticipating ? "3px solid limegreen" : "none",
                                        }}>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    overflow: "hidden",
                                                    filter: "brightness(0.6)",
                                                    borderRadius: isMobileOnly ? "" : "10px"
                                                }}
                                                onError={({currentTarget}) => {
                                                    currentTarget.onerror = null; // prrewards looping
                                                    currentTarget.src = "https://blog.therunningcollective.fr/wp-content/uploads/2023/03/Sans-titre-2.001.jpeg"
                                                }}
                                                src={
                                                    reward && reward.cover && reward.cover != "" ? reward.cover
                                                        : "https://blog.therunningcollective.fr/wp-content/uploads/2023/03/Sans-titre-2.001.jpeg"}/>
                                        </div>
                                    </div>

                                    <div style={{paddingTop: "10px"}}>
                                        <h1 style={{fontSize:"18px", fontWeight: "600", margin:"0", lineHeight: "22px"}}>
                                            {reward && reward.name && reward.name != "" ? reward.name : null} / {reward && reward.description && reward.description != "" ? reward.description : null}
                                        </h1>
                                    </div>
                                    <hr style={{marginBottom: "10px", marginTop: "10px"}}/>
                                    {reward && reward.bigDescription && reward.bigDescription != "" ?
                                        <div style={{
                                            border: "1px solid black",
                                            borderRadius: "5px",
                                            padding: "10px",
                                            marginBottom: "10px",
                                            overflow: "hidden"
                                        }}>
                                            <b>LA RÉCOMPENSE EN QUELQUES MOTS :</b>
                                            <h2  style={{fontSize:"14px", lineHeight:"22px", whiteSpace: 'pre-line'}} className="event-mobile-description">
                                                {reward.bigDescription}
                                            </h2>
                                        </div> : null }
                                        <div style={{textAlign: "center"}}>
                                            {
                                                reward.numberOfAttendes >= reward.winnersCount ? 
                                                <div>La récompense n'est plus disponible</div> : 
                                                    user.countOfTokens < reward.price ? 
                                                <div>Tu n'as pas assez de TRCoins pour cette récompense</div>
                                            :
                                            <button
                                                onClick={() => handleValideReward()}
                                                style={{
                                                    backgroundColor: "black",
                                                    color: "white",
                                                    borderRadius:'5px',
                                                    padding: "10px 30px",
                                                    border: "none",
                                                    fontSize: "15px",
                                                    fontWeight: 600
                                            }}>
                                                Echanger pour {reward.price}  <img src={"https://img.therunningcollective.fr/logo-trcoin-svg.svg"} style={{maxWidth: "16px", paddingBottom: "2px"}}/>
                                            </button> 
                                            }
                                        </div>
                                </div>
                            </div>
                            : loading ?
                                <div style={{textAlign: 'center'}}>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                    </Spinner>
                                </div>
                            : null
                    }
                </div>
            </LayoutOne>
        </Fragment>
    );
};

Reward.propTypes = {
    location: PropTypes.object
};

export default Reward;

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}