import {getIndividualItemArray} from "./product";

const adidasShoesSizes = [
    "36",
    "36⅔",
    "37",
    "37⅓",
    "38",
    "38⅔",
    "39⅓",
    "40",
    "40⅔",
    "41⅓",
    "42",
    "42⅔",
    "43⅓",
    "44",
    "44⅔",
    "45⅓",
    "46",
    "46⅔",
    "47⅓",
    "48",

]
const otherShoesSizes = [
    "35",
    "35½",
    "36",
    "36½",
    "37",
    "37½",
    "38",
    "38½",
    "39",
    "39½",
    "40",
    "40½",
    "41",
    "41½",
    "42",
    "42½",
    "43",
    "43½",
    "44",
    "44½",
    "45",
    "45½",
    "46",
    "46½",
    "47",
    "47½",
    "48"
]

const apparelSizes = [
    "XS",
    "S",
    "M",
    "L",
    "XL",
    "XXL"
]


export function getUnavailableSizes(product, selectedDeclination){
    let unavailableSizes = []
    let productSizes = getProductsIndividualSizes(selectedDeclination)

    switch (product.headCategory) {
        case "/products/shoes":
            switch (product.brand) {
                case "adidas":
                    unavailableSizes = compareSizesTab(productSizes, adidasShoesSizes)
                    return unavailableSizes
                case "Salomon":
                    unavailableSizes = compareSizesTab(productSizes, adidasShoesSizes)
                    return unavailableSizes
                case "Hoka One One":
                    unavailableSizes = compareSizesTab(productSizes, adidasShoesSizes)
                    return unavailableSizes
                case "NNormal":
                    unavailableSizes = compareSizesTab(productSizes, adidasShoesSizes)
                    return unavailableSizes
                default:
                    unavailableSizes = compareSizesTab(productSizes, otherShoesSizes)
                    return unavailableSizes
            }
        case "/products/spikes":
            switch (product.brand) {
                case "adidas":
                    unavailableSizes = compareSizesTab(productSizes, adidasShoesSizes)
                    return unavailableSizes
                default:
                    unavailableSizes = compareSizesTab(productSizes, otherShoesSizes)
                    return unavailableSizes
            }
        case "/products/apparel":
            unavailableSizes = compareSizesTab(productSizes, apparelSizes)
            return unavailableSizes
        default:
    }


}

// get individual sizes
export function getProductsIndividualSizes(product) {
    let productSizes = [];

    if (product){
        product.analysedRetailers &&
        product.analysedRetailers.map(retailer => {
                        return (retailer.sizes &&
                            retailer.sizes.map(size => {
                                if (size !== "questions les plus utiles" && size !== "questions les plus récentes" && size !== "questions les plus commentées") {
                                    return productSizes.push(size);
                                } else {
                                    return productSizes
                                }
                            }));
                    })
    }

    let uniqueSize = [...new Set(productSizes.sort())]
    const individualProductSizes = getIndividualItemArray(uniqueSize);
    return individualProductSizes;
};

function compareSizesTab(avalailableSizes, brandSizes){
    let unavailableSizes = []
    for (var i = 0; i < brandSizes.length; i++) {
        // we want to know if brandSizes[i] is found in avalailableSizes
        var match = false; // we haven't found it yet
        for (var j = 0; j < avalailableSizes.length; j++) {
            if (brandSizes[i] === avalailableSizes[j]) {
                // we have found brandSizes[i] in avalailableSizes, so we can stop searching
                match = true;
                break;
            }
            // if we never find brandSizes[i] in avalailableSizes, the for loop will simply end,
            // and match will remain false
        }
        // add brandSizes[i] to newArray only if we didn't find brandSizes match.
        if (!match) {
            unavailableSizes.push(brandSizes[i]);
        }
    }
    return unavailableSizes
}