import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ReactGA from "react-ga4";
import Card from "react-bootstrap/Card";
import {Button, Col, Nav, OverlayTrigger, Row, Spinner, Tab} from "react-bootstrap";
import axios from "axios";
import {isDesktop, isMobileOnly, isTablet} from "react-device-detect";
import {useTranslation} from "react-i18next";
import { getWithExpiry, setWithExpiry } from "../home/HomeFashionTwo";
import RewardContent from "../../components/header/RewardContent";

const MyRewards = ({ location }) => {
  const { pathname } = location;
  const { i18n } = useTranslation()
  const [origin, setOrigin] = useState("fr");
  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState(null);
  const [user, setUser] = useState(getWithExpiry('trc-front-user'));
  const [isAmbassador, setIsAmbassador] = useState(getWithExpiry("trc-front-is-ambassador"));
  const [ambassadorRewards, setAmbassadorRewards] = useState({});
  const [openRewardContent, setOpenRewardContent] = useState(false)
  const [selectedRewardContent, setSelectedRewardContent] = useState(null)

  const getProfile = () => {
    let query = 'https://public-front-api.therunningcollective.fr/profile'
    axios.get(query, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
            },
        }
    )
    .then((res) => {
        setUser(res.data);
        let user = res.data
        setWithExpiry("trc-front-user", user, 566400000)
        if (user && user.isAmbassadorApproved){
          setWithExpiry("trc-front-is-ambassador", user.isAmbassadorApproved, 566400000)
        } else {
          setWithExpiry("trc-front-is-ambassador", false, 566400000)
        }
    })
  }

  const getAmbassadorRewards = () => {
    setLoading(true)
    ReactGA.event({
        category: 'User',
        action: 'Get rewards'
    });

    let query = 'https://public-front-api.therunningcollective.fr/ambassador-rewards'
    axios.get(query, {
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
        }
        ,}
    )
        .then((res) => {
            setAmbassadorRewards(res.data)
        }).catch(function (error) {
        if (error.response) {
            // Request made and server responded
            if (error.response.data && error.response.data.includes("token is expired by")){
                localStorage.removeItem('trc-client-token');
                window.location.href="/sign-in";
            }
        }
    }).finally(() => {
        setLoading(false)
    });
  };

  useEffect(() => {
    getProfile();
    getRewards();
    getAmbassadorRewards();
    setOrigin(i18n.language)
  }, [])

  const handleRewardContent = (reward) => {
    if(reward && reward.voucher != ""){
      setOpenRewardContent(true); 
      setSelectedRewardContent(reward)
    }
  }

  useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [])

  const getRewards = () => {
    setLoading(true)
    ReactGA.event({
        category: 'User',
        action: 'Get rewards'
    });

    let query = 'https://public-front-api.therunningcollective.fr/rewards'
    axios.get(query, {
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('trc-client-token')).token}`
        }
        ,}
    )
        .then((res) => {
            setRewards(res.data)
        }).catch(function (error) {
        if (error.response) {
            // Request made and server responded
            if (error.response.data && error.response.data.includes("token is expired by")){
                localStorage.removeItem('trc-client-token');
                window.location.href="/sign-in";
            }
        }
    }).finally(() => {
        setLoading(false)
    });
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Mes récompenses</title>
        <meta
          name="description"
          content="Retrouvez ici tous les jeux concours disponibles"
        />
        <meta name="robots" content="noindex, nofollow"/>
      </MetaTags>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Accueil</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Mes récompenses
      </BreadcrumbsItem>
      <LayoutOne
          headerContainerClass="container-fluid"
          headerPaddingClass="header-padding-2"
          headerTop="visible"
      location={location}>
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="error-area pt-20 pb-20">
          <div className="container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="my-rewards">
            <Row>
              <Col xs={isMobileOnly ? 12 : 4}>
                <h1 className="h3 mb-3">Mes récompenses</h1>
              </Col>
            </Row>
              <Row style={{marginTop: isMobileOnly ? "20px" : ""}}>
                <Col md="5" xl="3">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="my-rewards">Récompenses disponibles</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="my-existing-rewards">Mes récompenses</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col md="7" xl="9">
                <Tab.Content>
              <Tab.Pane eventKey="my-rewards">
                <Row style={{marginBottom: "10px"}}>
                  <Col xs={12}>
                    Solde actuel : {user && user.countOfTokens}  <img src={"https://img.therunningcollective.fr/logo-trcoin-svg-short.svg"} style={{maxWidth: "16px", paddingBottom: "2px"}}/>
                  </Col>
                </Row>
                <Row>
                  {user && isAmbassador && ambassadorRewards && ambassadorRewards.length > 0 && rewards && rewards.length > 0 ?
                    ambassadorRewards.map(reward => {
                    return (
                      <Col xs={isMobileOnly ? 6 : 3} style={{textAlign: 'center', marginBottom: "20px", cursor: "pointer"}}
                      onClick={() => window.location.href = "/ambassador-reward/" + reward.id}>
                            <Card style={{width: '100%', borderRadius: "5px"}}>
                                <div style={{
                                    width:"100%", height: "156px"
                                }}>
                                <Card.Img variant="top"
                                            src={reward.cover}
                                            style={{height: "100%", width: "100", overflow: "hidden", borderRadius: "5px 5px 0 0"}}/>
                                <img variant="top"
                                        src={reward.logo}
                                        style={{
                                            marginLeft: "-70%",
                                            position: "absolute",
                                            maxWidth: "60px",
                                            borderRadius: "50%",
                                            top: "40%",
                                            border: "solid black 1px",
                                            zIndex: "100000"
                                        }}/>
                                </div>                                                             
                                <Card.Body>
                                    <Card.Text style={{
                                        fontSize: "11px",
                                        fontWeight: 500,
                                        lineHeight: '15px',
                                        marginTop: '12px'
                                    }}>
                                        {reward.description}
                                    </Card.Text>
                                    {
                                        reward.uniqueProduct ?
                                            <Card.Text
                                                style={{fontSize: "11px", fontWeight: 700}}>
                                                <span style={{
                                                    textTransform: "uppercase",
                                                    fontSize: "11px",
                                                    fontWeight: 500
                                                }}>
                                                    <b>{reward.price} <img src={"https://img.therunningcollective.fr/logo-trcoin-svg-short.svg"} style={{maxWidth: "16px", paddingBottom: "2px"}}/></b>
                                                </span>
                                            </Card.Text> : null
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                      )
                    })
                    : 
                    null 
                  }
                  {user && rewards && rewards.length > 0 ?
                    rewards.map(reward => {
                        if (user.level >= reward.minimumLevel) {
                            return (
                                <Col xs={isMobileOnly ? 6 : 3} style={{textAlign: 'center', marginBottom: "20px", cursor: "pointer"}}
                                      onClick={() => window.location.href = "/reward/" + reward.id}>
                                    <Card style={{width: '100%', borderRadius: "5px"}}>
                                        <div style={{
                                            width:"100%", height: "156px"
                                        }}>
                                        <Card.Img variant="top"
                                                  src={reward.cover}
                                                  style={{height: "100%", width: "100", overflow: "hidden", borderRadius: "5px 5px 0 0"}}/>
                                        <img variant="top"
                                              src={reward.logo}
                                              style={{
                                                  marginLeft: "-70%",
                                                  position: "absolute",
                                                  maxWidth: "60px",
                                                  borderRadius: "50%",
                                                  top: "40%",
                                                  border: "solid black 1px",
                                                  zIndex: "100000"
                                              }}/>
                                        </div>                                                             
                                        <Card.Body>
                                            <Card.Text style={{
                                                fontSize: "11px",
                                                fontWeight: 500,
                                                lineHeight: '15px',
                                                marginTop: '12px'
                                            }}>
                                                {reward.description}
                                            </Card.Text>
                                            <Card.Text
                                                style={{fontSize: "11px", fontWeight: 700}}>
                                                <span style={{
                                                    textTransform: "uppercase",
                                                    fontSize: "11px",
                                                    fontWeight: 500
                                                }}>
                                                        <b>{reward.price} <img src={"https://img.therunningcollective.fr/logo-trcoin-svg-short.svg"} style={{maxWidth: "16px", paddingBottom: "2px"}}/>
                                                        </b>
                                                    </span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        }

                    })
                    :
                    <Col xs={12}>
                      <div style={{textAlign: 'center'}}>
                        <div style={{marginTop: '40px'}}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden"></span>
                            </Spinner>
                        </div>
                      </div>
                    </Col>
                    }
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="my-existing-rewards">
                <RewardContent open={openRewardContent} setOpen={setOpenRewardContent} reward={selectedRewardContent}/>
                <Row>
                  {user && user.rewards && user.rewards.length > 0 && isMobileOnly ?
                      user.rewards.map(reward => {
                          return (
                              <div className="container" style={{marginLeft: "15px", marginRight: "15px", marginTop: "15px", cursor: reward.voucher != "" ? "pointer" : ""}} onClick={() => handleRewardContent(reward)}>
                                  <div className="row" style={{backgroundColor: "rgb(246, 246, 248)", borderRadius: "5px"}}>
                                      <div className="col-4 col-lg-4 col-sm-4" style={{paddingLeft: 0, height: '100px', width: '100px', overflow: 'hidden'}}>
                                          <img
                                              style={{maxWidth: '100px', borderRadius: '5px'}}
                                              src={reward.cover}
                                              alt={reward.name}
                                          />
                                      </div>
                                      <div className="col-8 col-lg-8 col-sm-8" style={{paddingLeft: 0, paddingRight: 0, paddingTop: '5px'}}>
                                          <div style={{textTransform: "uppercase"}}>
                                              <b>{reward.name}</b>
                                          </div>
                                          <div style={{fontSize: '13px', lineHeight: "16px", marginTop: "5px", paddingRight: "5px", whiteSpace: "pre-wrap"}} >
                                                {reward.description}
                                          </div>
                                          <div style={{fontSize: '13px', lineHeight: "16px", marginTop: "5px"}} >
                                              <b style={{textTransform: "uppercase"}}>Prix : </b>{reward.price}  <img src={"https://img.therunningcollective.fr/logo-trcoin-svg-short.svg"} style={{maxWidth: "16px", paddingBottom: "2px"}}/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          )
                      })
                      : user && user.rewards && user.rewards.length > 0 && !isMobileOnly ?
                      user.rewards.map(reward => {
                        return (
                            <Col xs={5} style={{marginLeft: "15px", marginRight: "15px", marginTop: "15px", cursor: reward.voucher != "" ? "pointer" : ""}} onClick={() => handleRewardContent(reward)}>
                                <div className="row" style={{backgroundColor: "rgb(246, 246, 248)", borderRadius: "5px"}}>
                                    <div className="col-4 col-lg-4 col-sm-4" style={{paddingLeft: 0, height: '100px', width: '100px', overflow: 'hidden'}}>
                                        <img
                                            style={{maxWidth: '100px', borderRadius: '5px'}}
                                            src={reward.cover}
                                            alt={reward.name}
                                        />
                                    </div>
                                    <div className="col-8 col-lg-8 col-sm-8" style={{paddingLeft: 0, paddingRight: 0, paddingTop: '5px'}}>
                                        <div style={{textTransform: "uppercase"}}>
                                            <b>{reward.name}</b>
                                        </div>
                                        <div style={{fontSize: '13px', lineHeight: "16px", marginTop: "5px", paddingRight: "5px", whiteSpace: "pre-wrap"}} >
                                              {reward.description}
                                        </div>
                                        <div style={{fontSize: '13px', lineHeight: "16px", marginTop: "5px"}} >
                                            <b style={{textTransform: "uppercase"}}>Prix : </b>{reward.price}  <img src={"https://img.therunningcollective.fr/logo-trcoin-svg-short.svg"} style={{maxWidth: "16px", paddingBottom: "2px"}}/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    }) :
                      <div className="col-md-12" style={{textAlign: 'center'}}>
                          <div>

                          </div>
                      </div>}
                </Row>
              </Tab.Pane>
              </Tab.Content>
                </Col>
              </Row>
          </Tab.Container>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

MyRewards.propTypes = {
  location: PropTypes.object
};

export default MyRewards;

function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
  diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}