import {getClientTokenData} from "../App";
import {getWithExpiry} from "../pages/home/HomeFashionTwo";

export function buildRedirectingLinkByUserID(retailer, origin) {
    if (!origin || origin == ""){
        origin = "fr"
    }

    let token = getClientTokenData()
    let source = getWithExpiry("trc-source")
    if(!source) {
        source = "website-"+origin
    }

    if (retailer.retailerName && token && source) {
        switch (retailer.retailerName) {
            case "top4running":
                return retailer.url + "&a_cha="+token.id + "_" + source
            case "i-Run":
                return retailer.url+"&argsite="+token.id + "_" + source
            case "directRunning":
                return retailer.url+"&argsite="+token.id + "_" + source
            case "alltricks":
                return retailer.url+"&effi_id2="+token.id + "_" + source
            case "colizey":
                return retailer.url+"&aev="+token.id + "_" + source
            case "nike":
                let url = new URL(retailer.url);
                let params = new URLSearchParams(url.search);
                let p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id + "_" + source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "proDirect":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id + "_" + source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "sportsShoes":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id + "_" + source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "hokaOneOne":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id + "_" + source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "maxiSports":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id + "_" + source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "jdSports":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id + "_" + source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "fnac":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id + "_" + source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "darty":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id + "_" + source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "newBalance":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id + "_" + source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "adidas":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id + "_" + source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "asics":
                return insert(retailer.url, 37, "/pubref:"+token.id + "_" + source)
            case "polar":
                return insert(retailer.url, 58, "&clickref="+token.id + "_" + source)
            case "kellerSports":
                return insert(retailer.url, 58, "&clickref="+token.id + "_" + source)
        }
    } else if (retailer.retailerName && token) {
        switch (retailer.retailerName) {
            case "top4running":
                return retailer.url + "&a_cha="+token.id
            case "i-Run":
                return retailer.url+"&argsite="+token.id
            case "directRunning":
                return retailer.url+"&argsite="+token.id
            case "alltricks":
                return retailer.url+"&effi_id2="+token.id
            case "colizey":
                return retailer.url+"&aev="+token.id
            case "nike":
                let url = new URL(retailer.url);
                let params = new URLSearchParams(url.search);
                let p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "proDirect":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "sportsShoes":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "hokaOneOne":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "maxiSports":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "jdSports":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "fnac":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "darty":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "newBalance":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "adidas":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", token.id)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "asics":
                return insert(retailer.url, 37, "/pubref:"+token.id)
            case "polar":
                return insert(retailer.url, 58, "&clickref="+token.id)
            case "kellerSports":
                return insert(retailer.url, 58, "&clickref="+token.id)
        }
    } else if(source) {
        switch (retailer.retailerName) {
            case "top4running":
                return retailer.url + "&a_cha="+source
            case "i-Run":
                return retailer.url+"&argsite="+source
            case "directRunning":
                return retailer.url+"&argsite="+source
            case "alltricks":
                return retailer.url+"&effi_id2="+source
            case "colizey":
                return retailer.url+"&aev="+source
            case "nike":
                let url = new URL(retailer.url);
                let params = new URLSearchParams(url.search);
                let p = params.get("p")
                params.delete("p");
                params.set("clickref2", source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "proDirect":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "sportsShoes":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "hokaOneOne":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "maxiSports":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "jdSports":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "fnac":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "darty":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "newBalance":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "adidas":
                url = new URL(retailer.url);
                params = new URLSearchParams(url.search);
                p = params.get("p")
                params.delete("p");
                params.set("clickref2", source)
                params.set("p", p)
                return url.origin + url.pathname+"?"+params.toString()
            case "asics":
                return insert(retailer.url, 37, "/pubref:"+source)
            case "polar":
                return insert(retailer.url, 58, "&clickref="+ source)
            case "kellerSports":
                return insert(retailer.url, 58, "&clickref="+ source)
        }
    }
    return retailer.url
}

function insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
}