import PropTypes from "prop-types";
import React, {Fragment, useEffect} from "react";
import {Modal} from "react-bootstrap";
import {connect} from "react-redux";
import ReactGA from "react-ga4";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

function ShoesSizeUpdateModal(props) {

  useEffect(() => {
    if (props.show) {
      ReactGA.event({
        category: 'Modal',
        action: 'Open modal /update/shoesSize'});
    }
  }, [props.show])

  function handleClose() {
    window.location.href="/menu?value=4";
  }

  return (
      <Fragment>
        <Modal
            size={"sm"}
            show={props.show}
            onHide={props.onHide}
            className="product-quickview-modal-wrapper"
        >
          <Modal.Header closeButton><h3>N'oubliez pas !</h3></Modal.Header>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p>Pour participer aux jeux-concours, vous devez renseigner votre taille de chaussures et de vêtements !</p>
                </div>
                <br/>
                <div>
                  <div className="pro-details-quality col-md-6 col-sm-6 col-xs-6">
                    <div className="pro-details-cart btn-hover ml-0">
                      <button
                          style={{borderRadius: "5px"}}
                          rel="noopener noreferrer"
                          onClick={() => handleClose()}
                      >
                        Mettre à jour
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
  );
}


ShoesSizeUpdateModal.propTypes = {
  unavailableSizes: PropTypes.array,
  onHide: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    cartitems: state.cartData
  };
};

export default connect(mapStateToProps)(ShoesSizeUpdateModal);