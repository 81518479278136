import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import {ButtonGroup, Col, Row, ToggleButton} from "react-bootstrap";
import {connect} from "react-redux";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import { isMobileOnly } from "react-device-detect";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

const AmbassadorHistory = ({ historyPrices, setHistoryPeriod, historyPeriod}) => {
    const [radioValue, setRadioValue] = useState('month');
    const [averagePrice, setAveragePrice] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [currentPriceCompared, setCurrentPriceCompared] = useState(0);

    const radios = [
        { name: 'Année', value: 'year' },
        { name: 'Mois', value: 'month' },
        { name: 'Semaine', value: 'week' },
      ];
    
      useEffect(() => {
        if(historyPrices && historyPrices.length >= 0) {
    
          setMinPrice(Math.min(...historyPrices.map(item => item.countOfView)));
          setMaxPrice(Math.max(...historyPrices.map(item => item.countOfView)));
    
          let total = 0
          historyPrices.map(price => {
            total += price.countOfView
          })
          setAveragePrice(total)
          if(historyPrices && historyPrices.length > 0 && isYesterday(new Date(historyPrices[historyPrices.length -1].createdDate))){
            setCurrentPriceCompared(historyPrices[historyPrices.length -1].countOfView)
          }
        }
      }, [historyPrices]);
    
    
      const getHistoryPriceLabel = (historyPrices) => {
        let labels = []
        const formatter = new Intl.DateTimeFormat('fr', { month: 'short' });
        historyPrices.map(price => {
          let date = new Date(price.createdDate)
          let str = date.getDate() + " " + formatter.format(date).substring(0, 3)
          labels.push(str)
        })

        return labels
      }
    
      const getHistoryPriceData = (historyPrices) => {
        let labels = []
    
        historyPrices.map(price => {
          labels.push(price.countOfView)
        })
        return labels
      }
    
      const orderPricesHistoryData = (historyPrices) => {
        if(historyPrices && historyPrices.length >= 0){
          let data = historyPrices.sort((a,b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())
    
          const testData = {
            labels: getHistoryPriceLabel(data),
            datasets: [
              {
                label: "Nombre de visites",
                data: getHistoryPriceData(data),
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)",
              },
            ]
          };
    
          return testData
        }
      }
    
      const chartDataOptions = {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
    
          tooltip: {
            callbacks: {
              label: function(context) {
                return context.parsed.y;
              }
            }
          }
        },
        scales: {
          x: {
            ticks:{
              autoSkip: true,
              maxTicksLimit: 10
            }
          },
          y: {
            suggestedMin: 0,
            suggestedMax: maxPrice > 10 ? 30 : 5,
            ticks: {
              stepSize: maxPrice > 100 ? 10 : maxPrice > 1000 ? 100 : 1,
              // Include a dollar sign in the ticks
              callback: function(value, index, ticks) {
                return value ;
              }
            }
          }
        }
      }

      return (
        <div className="container">
            <div className="description-review-bottom">
                {historyPrices && historyPrices.length >= 0 ?
                    <div style={{border: 'solid 2px', padding: '5px', borderRadius: "7px", marginBottom : "20px"}}>
                        <div>
                        <ButtonGroup className="mb-2" style={{marginTop: '5px', marginLeft: '5px'}}>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    variant="secondary"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                                    onClick={(e) => setHistoryPeriod(e.target.value)}
                                    style={{fontSize: '10px'}}
                                >
                                {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                        </div>
                        {
                          historyPrices && historyPrices.length >= 0 ?
                            <div>
                                <Line data={orderPricesHistoryData(historyPrices)} options={chartDataOptions} style={{maxHeight: '300px'}}/>
                                <div className="row" style={{paddingLeft: "20px", paddingRight: '20px', marginTop: '10px', marginBottom: '15px'}}>
                                <div className="col-md-4" style={{padding: '5px 15px 5px 15px'}}>
                                    <div style={{backgroundColor: '#f7f7f7', padding: '15px'}}>
                                    <div style={{fontSize: '18px'}}><b>{averagePrice}</b></div>
                                    <span style={{fontSize: isMobileOnly ? '10px' : "12px"}}>Visites totales sur {historyPeriod == "year" ? "l'année" : historyPeriod == "month" ? "le mois" : "la semaine"}.</span>
                                    </div>
                                </div>
                                <div className="col-md-4" style={{padding: '5px 15px 5px 15px'}}>
                                    <div style={{backgroundColor: '#f7f7f7', padding: '15px'}}>
                                    <div>
                                            <div style={{fontSize: '18px', color: 'green'}}><b>{currentPriceCompared}</b></div>
                                            <span style={{fontSize: isMobileOnly ? '10px' : "12px"}}>Nombres de visites hier.</span>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            : null
                        }
                    </div> : 
                    null}
                </div>
            <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
        </div>
      )
}


AmbassadorHistory.propTypes = {
    currentDeclination: PropTypes.object,
    productFullDesc: PropTypes.string,
    product: PropTypes.object,
    posts: PropTypes.any,
    spaceBottomClass: PropTypes.string,
    othersSizesRef: PropTypes.any,
    productRating: PropTypes.array,
    historyPrices: PropTypes.any,
    setHistoryPeriod: PropTypes.func,
    setCurrentTab: PropTypes.func,
    currentTab: PropTypes.any,
    setAverageHistory: PropTypes.func,
    selectedUserSize: PropTypes.any,
    setCountOfReporting: PropTypes.any,
    historyPrices : PropTypes.array,
  };
  
  const mapStateToProps = state => {
    return {
      currency: state.currencyData,
      cartItems: state.cartData,
      wishlistItems: state.wishlistData,
      compareItems: state.compareData
    };
  };

  
  export default connect(mapStateToProps)(AmbassadorHistory);

  function isYesterday (date) {  
    if (!(date instanceof Date)) {
      throw new Error('Invalid argument: you must provide a "date" instance')
    }
  
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
  
      return date.getDate() === yesterday.getDate() &&
           date.getMonth() === yesterday.getMonth() &&
           date.getFullYear() === yesterday.getFullYear()
  }
