import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import SnackbarCredits from "../Snackbar-credits";
import {useEffect} from "react";
import axios from "axios";
import {sleep} from "../../helpers/tools";
import Snackbar from "@mui/material/Snackbar";
import {useState} from "react";
import moment from "moment";
import TextField from '@mui/material/TextField';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function RewardContent({open, setOpen, reward}) {
    const handleClose = () => {
        setOpen(false)
    }

    return (
        reward ?
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:"center", lineHeight: "24px"}}>
                        Contenu de la récompense {reward.name}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:"14px", textAlign:"center"}}>
                        <div>
                            <h2  style={{fontSize:"14px", lineHeight:"22px", whiteSpace: 'pre-line'}} className="reward-mobile-description">
                                Voici le code obtenu :
                            </h2>
                        </div>
                        <h2  style={{fontSize:"23px", lineHeight:"22px", whiteSpace: 'pre-line'}} className="reward-mobile-description">
                            {reward.voucher}
                        </h2>
                    </Typography>
                    
                    <Row style={{marginTop: "30px", textAlign:"center"}}>
                        <Col>
                            <button
                                onClick={() => handleClose()}
                                style={{
                                    backgroundColor: "rgb(203, 200, 200)",
                                    color: "black",
                                    borderRadius:'5px',
                                    padding: "5px 40px",
                                    border: "none",
                                    fontSize: "15px",
                                    fontWeight: 600
                                }}>
                                Fermer
                            </button>
                        </Col>
                    </Row>

                </Box>
            </Modal>
        </div> : null
    );
}

RewardContent.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    reward: PropTypes.any,
};

export default RewardContent;