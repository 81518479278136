import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import axios from "axios";
import NewsletterProductModal from "./NewsletterProductModal";
import {getUnavailableSizes} from "../../helpers/sizes";
import ReactGA from "react-ga4";
import {
    getMerchandDeliveryTimeFromName,
    getMerchandLogoFromName,
    getMerchandReturnTimeFromName
} from "../../wrappers/product/ProductDescriptionTab";
import NewsletterPriceModal from "./NewsletterPriceModal";
import {useHistory} from "react-router";
import {isBrowser, isMobileOnly, BrowserView, MobileView} from "react-device-detect";
import AliceCarousel from "react-alice-carousel";
import {getAvailablesBrandSizes, getSizeReference, getSizesStatus, getSizeStyle} from "../../pages/shop-product/tools";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import {apiConversionRequest, getClientTokenData, GetUserData} from "../../App";
import {buildRedirectingLinkByUserID} from "../../helpers/redirecting-user";
import {useTranslation} from "react-i18next";
import {ReactComponent as ScissorsLogo} from '../../assets/img/scissors-svgrepo-com.svg';
import PimsterWidget from "../PimsterWidget";
import { TbBrandCarbon } from "react-icons/tb";
import GenerateAmbassadorLinkModal from "../header/GenerateAmbassadorLinkModal";

export const redirection = (retailer, origin) => {
    let retailerURL = buildRedirectingLinkByUserID(retailer, origin)
    if (retailer){
        if (retailer.retailerName) {
            switch (retailer.retailerName) {
                case "KellerSports":
                    window.open(retailerURL, '_blank')
                case "Polar":
                    window.open(retailerURL, '_blank')
                case "sportShoes":
                    window.open(retailerURL, "_blank")
                default:
                    window.open('https://app.therunningcollective.'+origin+ "/redirection?redirectionURL="+ decodeURI(retailerURL), '_blank')
            }
        }
    } else {
        window.open('https://app.therunningcollective.'+origin+ + "/redirection?redirectionURL="+ decodeURI(retailerURL), '_blank')
    }
}

const ProductDescriptionInfo = ({
  product,
  currentDeclination,
  discountedPrice,
  currency,
  finalDiscountedPrice,
  finalProductPrice,
  setCurrentDeclination,
  pathname,
  productRating,
  setCurrentTab,
  averageHistory,
  setSelectedUserSize
}) => {
    const [selectedProductDeclination, setSelectedProductDeclination] = useState(
        currentDeclination ? currentDeclination : {}
    );

    const [modalShow, setModalShow] = useState(false);
    const [modalPriceShow, setModalPriceShow] = useState(false);
    const [ratingProductStars, setRatingProductStars] = useState(0);
    const { t } = useTranslation();

    const unavailableSizes = getUnavailableSizes(product, selectedProductDeclination);

    const [voucherInfo, setVoucherInfo] = useState(null)
    const [productSizes, setProductSizes] = useState([])
    const [avalaibleMerchands, setAvalaibleMerchands] = useState([]);
    const [adidasVoucher, setAdidasVoucher] = useState(null)
    const [showVoucher, setShowVoucher] = useState(false)
    const [carouselPrincipalItems, setCarouselPrincipalItems] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0)
    const handleDragStart = (e) => e.preventDefault();
    const { i18n } = useTranslation();
    const [origin, setOrigin] = useState("fr")
    const [isAmbassador, setIsAmbassador] = useState(false);
    const [openExportAmbassadorProduct, setOpenExportAmbassadorProduct] = useState(false);

    useEffect(() => {
      let token = getClientTokenData();
      if (token && token.isAmbassador){
        setIsAmbassador(true)
      }
    }, [])

    useEffect(() => {
        setOrigin(i18n.language)
    }, [])

    useEffect(() => {
        setProductSizes(getAvailablesBrandSizes(product.brand, product.headCategory))
        let items = []
        if (product.declinations){
            if (product.declinations.length > 0) {
                product.declinations.map((declination, index) => {
                    if (declination.reference !== currentDeclination.reference){
                        items.push(
                            <div onClick={() => {
                                setSelectedProductDeclination(declination);
                                setCurrentDeclination(declination);
                                handleClick(pathname, declination.reference)
                                ReactGA.event({
                                    category: 'Product',
                                    action: 'Change product ' + product.name  + ' color : ' + declination.color
                                });
                            }} style={{width: '70px', paddingRight: '10px'}}>
                                <img style={{width: '100%', borderRadius: "5px"}} src={declination.firstImg} onDragStart={handleDragStart} role="presentation" alt={product.name + " - " + declination.reference + " au meilleur prix ! ("+index+")"}/>
                            </div>
                        )
                    } else {
                        setActiveIndex(index)
                        items.push(
                            <div onClick={() => {
                                setSelectedProductDeclination(currentDeclination);
                                setCurrentDeclination(currentDeclination);
                                handleClick(pathname, currentDeclination.reference)
                                ReactGA.event({
                                    category: 'Product',
                                    action: 'Change product ' + product.name  + ' color : ' + currentDeclination.color
                                });
                            }} style={{width: '70px', paddingRight: '10px'}}>
                                <img style={{width: '100%', border: 'solid 1px', borderRadius: "5px"}} src={currentDeclination.firstImg} onDragStart={handleDragStart} role="presentation" alt={product.name + " - " + currentDeclination.reference + " au meilleur prix !"}/>
                            </div>
                        )
                    }
                })
            }
            setCarouselPrincipalItems(items)
        }
    }, [currentDeclination]);

    const handleClick = (pathname, reference) =>  window.history.replaceState(null, "", [pathname + "?reference=" + reference])

    const fetchMerchandsData = () => {
        let query = 'https://public-front-api.therunningcollective.fr/merchants'

        axios
            .get(query)
            .then((res) => {
                setAvalaibleMerchands(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const generateVoucher = (voucherName) => {
        if (voucherName === "adidastogenerate" || voucherName === "adidastogenerateextra"){
            return adidasVoucher
        } else {
            return voucherName
        }
    }

    let othersRetailers = []
    if (currentDeclination.analysedRetailers){
        othersRetailers = currentDeclination.analysedRetailers.filter(retailer => {return retailer.retailerName !== currentDeclination.bestRetailer.retailerName})
        othersRetailers = othersRetailers.filter(retailer => {return retailer.active})
        othersRetailers.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0))
        getSizesStatus(currentDeclination.bestRetailer, othersRetailers, productSizes, product.declinations)

    }

    const redirectionOtherDeclination = (productSize, declinations, shortURL, productSizes, setCurrentDeclination) => {
        let declination = getSizeReference(declinations, productSizes, productSize)
        
        if (productSize.status == "othersDeclinations") {
            setCurrentDeclination(declination)
            window.history.replaceState(null, "", [pathname + "?reference=" + declination.reference])
        }
    }

    useEffect(() => {
        fetchMerchandsData();
        if(selectedProductDeclination.bestRetailer.retailerName === "adidas") {

        } else {
            setShowVoucher(true)
        }
    }, [selectedProductDeclination, showVoucher]);

    useEffect(() => {
        if(productRating && productRating.length > 0) {
            let stars = 0
            productRating.map(rating => {
                stars += rating.stars
            })
            stars = stars / productRating.length
            setRatingProductStars(stars)
        }
    }, [productRating]);

    const scroll = (tab) => {
        const section = document.querySelector( '#othersSizes' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        setCurrentTab(tab);
        return
    };

    const scrollDesktop = (tab) => {
        const section = document.querySelector( '#othersSizesDesktop' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        setCurrentTab(tab)
        return;
    };

    const activeDots = (product) => {
        if(product.declinations.length > 5 && isMobileOnly){
            return false
        } else if (product.declinations.length > 7 && !isMobileOnly) {
            return false
        }
        return true

    }

    const renderTooltipBestDeclination = (status) => (
        <Tooltip id="button-tooltip" >
            <div>{
                status == "available" ?
                    <span>{t('seeproductpartener')}</span>
                    :
                    status == "othersRetailers" ?
                        <span>{t('seeotherspartners')}</span> :
                        status == "othersDeclinations" ?
                            <span>{t('seeothersreference')}</span>
                        : <span>{t('nostocksize')}</span>
            }</div>
        </Tooltip>
    );

    const fetchVoucherInfo = (slug, reference, merchandName) => {
        let query = 'https://public-front-api.therunningcollective.fr/voucher-info?productName='+slug+'&reference='+reference+'&merchandName='+merchandName
        axios
            .get(query)
            .then((res) => {
                setVoucherInfo(res.data)
            })
            .catch((err) => {
                console.log(err);
            })
    };

    /*useEffect(() => {
        if(selectedProductDeclination.bestRetailer.retailerName && product.shortURL && selectedProductDeclination.reference && origin == "fr"){
            fetchVoucherInfo(product.shortURL, selectedProductDeclination.reference, selectedProductDeclination.bestRetailer.retailerName)
        }
    }, [origin])*/

    return (
        <div className="product-details-content ml-70">
            <BrowserView>
                <h1>{product.name} {product.headCategory == "/products/nutrition" && product.brand != "Maurten" ? " (" + selectedProductDeclination.color + ")" : null}</h1>
            </BrowserView>

            {
                isMobileOnly ?
                    <div>
                        <div className="pro-details-size-color">
                            <div className="pro-details-color-wrap">
                                {product.declinations.length == 1 ?
                                    <span>{product.declinations.length} {t('availablecolor')} :</span>
                                    :
                                    <span>{product.declinations.length} {t('availablecolors')} :</span>
                                }
                                <div style={{cursor: 'pointer'}}>
                                    {carouselPrincipalItems && carouselPrincipalItems.length > 0 ?
                                        <AliceCarousel
                                            mouseTracking
                                            items={carouselPrincipalItems}
                                            disableButtonsControls={true}
                                            disableDotsControls={activeDots(product)}
                                            autoWidth={true}
                                            activeIndex={activeIndex}
                                        />
                                        : null}
                                </div>
                            </div>
                        </div>
                        {
                            product && product.pimsterProductName && product.pimsterProductName != "" && product.pimsterModuleID  && product.pimsterModuleID != "" ?
                                <>
                                    <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}>
                                    </div>
                                    <div style={{marginTop: '10px', marginBottom: "10px"}}>
                                        <PimsterWidget isProductPage={true} productName={product.pimsterProductName} moduleID={product.pimsterModuleID}/>
                                    </div>
                                </>
                            : null
                        }
                        
                        <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}>
                        </div>
                    </div>
                    : null
            }
            {/*<div className="certificate">&nbsp;{product.trainingApproved ?
                <i title={"Produit approuvé pour les entrainements"} style={{color: 'green'}}
                   className="fas fa-running"></i>
                : null
            }
                {product.raceApproved ?
                    <i title={"Produit ayant réalisé un record du monde"} style={{color: 'gold'}}
                       className="fas fa-trophy"></i>
                    : null
                }</div>*/}

            <div className="product-details-price">
                {(discountedPrice !== null && finalDiscountedPrice > 0 && finalProductPrice !== finalDiscountedPrice) ? (
                    <Fragment>
                        {selectedProductDeclination.bestRetailer.retailerName != "" && averageHistory <= 0 ?
                            <span>
                              <i className="fa-solid fa-arrow-trend-down" style={{color:'green'}}></i>&nbsp;&nbsp;
                            </span>
                            : averageHistory > 15 && selectedProductDeclination.bestRetailer.retailerName != "" ?
                                <span>
                          <i className="fa-solid fa-arrow-trend-up" style={{color:'orange'}}></i>&nbsp;&nbsp;
                        </span> : null
                        }

                        {selectedProductDeclination.bestRetailer.amount > 2 && selectedProductDeclination.bestRetailer.amount !== 100 ? (
                            <div>
                                <span>{finalDiscountedPrice + currency.currencySymbol}</span>{" "}
                                <span className="old" style={{color: 'grey'}}>
                                    { finalProductPrice + currency.currencySymbol}
                                </span>&nbsp;
                            </div>
                        ) : (
                            <div>
                                <span>{finalDiscountedPrice + currency.currencySymbol}</span>{" "}
                            </div>
                        )}
                        <div style={{paddingLeft: '10px'}}>
                            {selectedProductDeclination.bestRetailer.retailerName !== ""
                                ?
                                <img
                                    src={getMerchandLogoFromName(selectedProductDeclination.bestRetailer.retailerName, avalaibleMerchands, t, i18n)}
                                    className="merchand-logo-url-best"
                                    alt=""
                                />
                            : null
                            }

                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        {selectedProductDeclination.bestRetailer.retailerName != "" && averageHistory <= 0 ?
                            <span>
                              <i className="fa-solid fa-arrow-trend-down" style={{color:'green'}}></i>&nbsp;&nbsp;
                            </span>
                            : averageHistory > 15 && selectedProductDeclination.bestRetailer.retailerName != "" ?
                            <span>
                          <i className="fa-solid fa-arrow-trend-up" style={{color:'orange'}}></i>&nbsp;&nbsp;
                        </span> : null
                        }
                        <span>{finalProductPrice + currency.currencySymbol}</span>&nbsp;&nbsp;
                        <div>
                            {selectedProductDeclination.bestRetailer.retailerName !== ""
                                ?
                                <img
                                    src={getMerchandLogoFromName(selectedProductDeclination.bestRetailer.retailerName, avalaibleMerchands, t, i18n)}
                                    className="merchand-logo-url-best"
                                    alt=""
                                />
                                : null
                            }
                        </div>
                    </Fragment>
                    )}
            </div>

            {selectedProductDeclination.bestRetailer.retailerName !== ""
                ?
                <div>
                    {selectedProductDeclination.bestRetailer.retailerName !== ""
                        ?
                        <div style={{textAlign: "left"}}>
                            <span style={{fontWeight: 500}}>{t('sendingRate')}</span> <i>{getMerchandDeliveryTimeFromName(selectedProductDeclination.bestRetailer.retailerName, avalaibleMerchands, t, i18n)}</i>.
                        </div>
                        : null
                    }
                    {averageHistory <= 0 ?
                        <div style={{color:'green'}} onClick={() => {isMobileOnly ? scroll("historyPrices") : scrollDesktop("historyPrices")}}>
                            {t('decreasepricenow')}&nbsp;
                            <div style={{cursor: "pointer"}}>
                                <b style={{color: 'black'}}><span style={{color: 'red'}}>➔</span>{t('seehistorical')}</b>
                            </div>
                        </div>
                        : averageHistory > 15 ?
                        <div style={{color:'orange'}} onClick={() => {isMobileOnly ? scroll("historyPrices") : scrollDesktop("historyPrices")}}>
                            {t('pricedecrease7days')}&nbsp;
                            <div style={{cursor: "pointer"}}>
                                <b style={{color: 'black'}}><span style={{color: 'red'}}>➔</span>{t('seehistorical')}</b>
                            </div>
                        </div> : null
                    }

                    <div style={{marginBottom: '6px'}}>
                        <p style={{cursor: "pointer"}} onClick={() => setModalPriceShow(true)}><u>{t('toohighforyou')}</u></p>
                    </div>
                </div>
                : null
            }
            <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
            {
                product && product.pimsterProductName && product.pimsterProductName != "" && product.pimsterModuleID  && product.pimsterModuleID != "" ?
                    <BrowserView>
                        <Row style={{marginTop: '10px', marginBottom: "20px"}}>
                            <Col xs={3}>
                                <PimsterWidget isProductPage={true} productName={product.pimsterProductName} moduleID={product.pimsterModuleID}/>
                            </Col>
                            <Col xs={9}>
                            {
                                product && product.headCategory == "/products/shoes" ?
                                    <>
                                        <div>
                                            <Row>
                                                <Col xs={isMobileOnly ? 6 : 3}>
                                                    <div className="pro-details-meta">
                                                        <span><b style={{fontSize: "16px", color : "black"}}>Drop :</b> {product.drop && product.drop !== "" ? product.drop + "mm" : "N/A"}</span>
                                                    </div>
                                                    <div className="pro-details-meta">
                                                        <span><b style={{fontSize: "16px", color : "black"}}>Stack :</b> {product.stack && product.stack !== "" ? product.stack: "N/A"}</span>
                                                    </div>
                                                </Col>
                                                <Col xs={isMobileOnly ? 6 : 4}>
                                                    <div className="pro-details-meta">
                                                        <span><b style={{fontSize: "16px", color : "black"}}>Poids :</b> {product.weigh && product.weigh !== "" ? product.weigh + "g" : "N/A"}</span>
                                                    </div>
                                                    <div className="pro-details-meta">
                                                        <span><b style={{fontSize: "16px", color : "black"}}>Type d'amorti :</b> {product.cushioningType && product.cushioningType !== "" ? product.cushioningType : "N/A"} {product.isCarbonPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque carbone</Tooltip>}><TbBrandCarbon size={"1rem"}/></OverlayTrigger> : null}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                : null
                            }
                            </Col>
                        </Row>
                        <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                    </BrowserView>
                : null
            }

            { isAmbassador ?
                <>
                    <div>
                        <Row>
                            <Col xs={12}>
                                <div className="pro-details-quality" style={{display: "block", margin: "0", marginTop: "10px"}}>
                                    <div className="pro-details-cart ml-0" style={{margin: "0"}}>
                                        <a
                                            href={null}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                            onClick={() => {
                                                setOpenExportAmbassadorProduct(!openExportAmbassadorProduct)
                                                ReactGA.event({
                                                    category: 'Product',
                                                    action: 'Export product ' + product.name
                                                });
                                            }}
                                        >
                                            Créer un lien ambassadeur
                                        </a>
                                    </div> 
                                </div>  
                            </Col>
                        </Row>
                    </div>
                    <div className="pro-details-list"></div>              
                </> : null
            }

            <BrowserView>
            {
                product && product.headCategory == "/products/shoes" && (!product.pimsterProductName || product.pimsterProductName == "" || !product.pimsterModuleID  || product.pimsterModuleID == "") ?
                    <>
                        <div>
                            <Row>
                                <Col xs={isMobileOnly ? 6 : 3}>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Drop :</b> {product.drop && product.drop !== "" ? product.drop + "mm" : "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Stack :</b> {product.stack && product.stack !== "" ? product.stack: "N/A"}</span>
                                    </div>
                                </Col>
                                <Col xs={isMobileOnly ? 6 : 4}>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Poids :</b> {product.weigh && product.weigh !== "" ? product.weigh + "g" : "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Type d'amorti :</b> {product.cushioningType && product.cushioningType !== "" ? product.cushioningType : "N/A"} {product.isCarbonPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque carbone</Tooltip>}><TbBrandCarbon size={"1rem"}/></OverlayTrigger> : null}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                    </>
                : null
            }   
            </BrowserView>


            <BrowserView>
                <div className="pro-details-size-color">
                    <div className="pro-details-color-wrap">
                        {product.declinations.length == 1 ?
                            <span>{product.declinations.length} {t('availablecolor')} :</span>
                            :
                            <span>{product.declinations.length} {t('availablecolors')} :</span>
                        }
                        <div style={{cursor: 'pointer'}}>
                            {carouselPrincipalItems && carouselPrincipalItems.length > 0 ?
                                <AliceCarousel
                                    mouseTracking
                                    items={carouselPrincipalItems}
                                    disableButtonsControls={true}
                                    disableDotsControls={activeDots(product)}
                                    autoWidth={true}
                                    activeIndex={activeIndex}
                                />
                                : null}
                        </div>
                    </div>
                </div>
                <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
            </BrowserView>

            {product.declinations ? (
                <div className="pro-details-size-color">
                    {selectedProductDeclination.bestRetailer.url ? (
                            <div style={{marginBottom: '15px', textAlign: 'center'}}>
                                <div className="pro-details-quality">
                                    <div className="pro-details-cart ml-0" style={{width: "100%", margin: "0"}}>
                                        <Row>
                                            <Col xs={showVoucher && selectedProductDeclination.bestRetailer.vouchers && selectedProductDeclination.bestRetailer.vouchers.length === 1 ? 6 : 12}>
                                                <a
                                                    href={null}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{borderRadius: "5px", width: "100%", lineHeight: "16px"}}
                                                    onClick={() => {
                                                        redirection(selectedProductDeclination.bestRetailer, origin)
                                                        ReactGA.event({
                                                            category: 'Product',
                                                            action: 'Redirect to ' + selectedProductDeclination.bestRetailer.retailerName
                                                        });
                                                        ReactGA.event({
                                                            category: 'Product',
                                                            action: 'Redirect to ' + selectedProductDeclination.bestRetailer.retailerName + " for product : " + product.name
                                                        });
                                                        ReactPixel.track('AddToCart', {
                                                            content_name: product.name,
                                                            content_type: 'product',
                                                            content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                            content_ids: selectedProductDeclination.reference,
                                                            value: finalDiscountedPrice !=0 ? finalDiscountedPrice : product.price,
                                                            currency: 'EUR',
                                                        })
                                                    }}
                                                >
                                                    Voir l'offre
                                                </a>
                                            </Col>
                                            { showVoucher && selectedProductDeclination.bestRetailer.vouchers && selectedProductDeclination.bestRetailer.vouchers.length === 1 ?
                                                <Col xs={6}>
                                                    <div style={{
                                                        borderRadius: "5px",
                                                        padding: "10px 10px 5px 10px",
                                                        color: "#4fa0a6",
                                                        fontSize: "18px",
                                                        border: "3px dashed #4fa0a6",
                                                        borderImage: "https://img.therunningcollective.fr/scissors_01-svg",
                                                        height: "100%",
                                                    }}>
                                                        <div style={{
                                                            float: "left",
                                                            padding: "0px 5px",
                                                            margin: "-20px 0px -10px",
                                                            background: "#fff",
                                                            fontSize: "13px"
                                                        }}><ScissorsLogo style={{height: "20px",
                                                            width: "20px",
                                                            transform: "rotate(90deg)"}}/></div>
                                                        {generateVoucher(selectedProductDeclination.bestRetailer.vouchers[0].name)}
                                                    </div>
                                                </Col>
                                                :
                                                null
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        ) :
                        <div>
                            <p><b>Ce produit est malheureusement indisponible.</b></p>
                            <p style={{cursor: "pointer"}} onClick={() => setModalShow(true)}><u>Vous souhaitez être averti
                                lorsqu'il sera de nouveau en stock?</u></p>
                        </div>
                    }
                    {
                        selectedProductDeclination && selectedProductDeclination.active && productSizes && productSizes.length > 0 ?
                            <div className="pro-details-size">
                                <span>{t('availablesizes')} </span>
                                <div className="pro-details-size-content">
                                    {productSizes.map(productSize => {
                                return (
                                            productSize.status != "unavailable" ?
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={renderTooltipBestDeclination(productSize.status)}
                                                >
                                                    <a
                                                        className="button-product-size"
                                                        rel="noopener noreferrer"
                                                        href={null}
                                                        target="_blank"
                                                        onClick={() =>{
                                                            if (productSize.status == "othersDeclinations") {
                                                                redirectionOtherDeclination(productSize, product.declinations, product.shortURL, productSizes, setCurrentDeclination)
                                                            }
                                                            if (productSize.status == "available") {
                                                                redirection(selectedProductDeclination.bestRetailer, origin)
                                                                ReactPixel.track('AddToCart', {
                                                                    content_name: product.name,
                                                                    content_type: 'product',
                                                                    content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                                    content_ids: selectedProductDeclination.reference,
                                                                    value: finalDiscountedPrice !=0 ? finalDiscountedPrice : product.price,
                                                                    currency: 'EUR',
                                                                })
                                                                GetUserData(true).then(data =>
                                                                    apiConversionRequest(data.query, "AddToCart", {value: selectedProductDeclination.bestRetailer.price > 0 ?
                                                                            selectedProductDeclination.bestRetailer.price : selectedProductDeclination.price,
                                                                        productID: selectedProductDeclination.reference, productName: product.name})
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        <label
                                                            className={`pro-details-size-content--single`}
                                                            style={getSizeStyle(productSize.status)}
                                                            key={productSize.size}
                                                            onClick={() => {
                                                                if (productSize.status == "othersRetailers" && isMobileOnly) {
                                                                    scroll("otherSizes")
                                                                    setSelectedUserSize(productSize.size)
                                                                } else if (productSize.status == "othersRetailers") {
                                                                    scrollDesktop("otherSizes")
                                                                    setSelectedUserSize(productSize.size)
                                                                }
                                                            }
                                                            }
                                                        >
                                                            <span className="size-name">{productSize.size}</span>
                                                        </label>
                                                    </a>
                                                </OverlayTrigger>
                                                :
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={renderTooltipBestDeclination(productSize.status)}
                                                    >
                                                        <a
                                                            className="button-product-size"
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                            style={{cursor: 'pointer'}}
                                                        >
                                                            <label
                                                            className={`pro-details-size-content--single`}
                                                            style={getSizeStyle(productSize.status)}
                                                            key={productSize.size}
                                                        >
                                                            <span className="size-name">{productSize.size}</span>
                                                        </label>
                                                        </a>

                                                    </OverlayTrigger>


                                )

                            })}
                                </div>
                            </div>
                            : null
                    }
                </div>
            ) : (
                ""
            )}

            {othersRetailers && othersRetailers.length > 0 && (product.headCategory != "/products/accessories" && product.headCategory != "/products/nutrition" && product.headCategory != "/products/electronic") ?
                <div className="row" style={{marginTop: "20px", textAlign: "center"}}>
                    <div className="col-3">
                        <div style={{
                            display: "flex",
                            width: "20px",
                            height: "20px",
                            border: "solid 2px #7ac066",
                            margin: "auto",
                            marginBottom: "5px",
                            borderRadius: "5px"
                        }}>
                        </div>
                        <div>
                            <span>{t('instockthispartner')}</span>
                        </div>
                    </div>
                    <div className="col-3">
                        <div style={{
                            display: "flex",
                            width: "20px",
                            height: "20px",
                            border: "solid 2px #FDB52F",
                            margin: "auto",
                            marginBottom: "5px",
                            borderRadius: "5px"
                        }}>
                        </div>
                        <div>
                            <span>{t('instockotherpartner')}</span>
                        </div>
                    </div>
                    <div className="col-3">
                        <div style={{
                            display: "flex",
                            width: "20px",
                            height: "20px",
                            border: "solid 2px #048b9a",
                            margin: "auto",
                            marginBottom: "5px", opacity: "0.4",
                            borderRadius: "5px"
                        }}>
                        </div>
                        <div>
                            <span>En stock dans un autre coloris</span>
                        </div>
                    </div>
                    <div className="col-3">
                        <div style={{
                            display: "flex",
                            width: "20px",
                            height: "20px",
                            border: "solid 2px grey",
                            margin: "auto",
                            marginBottom: "5px",
                            opacity: "0.3",
                            borderRadius: "5px"
                        }}>
                        </div>
                        <div>
                            <span>{t('nostock')}</span>
                        </div>
                    </div>
                </div>
                : selectedProductDeclination && selectedProductDeclination.active && productSizes && productSizes.length ?
                <div className="row" style={{marginTop: "20px", textAlign: "center"}}>
                    <div className="col-4">
                        <div style={{
                            display: "flex",
                            width: "20px",
                            height: "20px",
                            border: "solid 2px #7ac066",
                            margin: "auto",
                            marginBottom: "5px",
                            borderRadius: "5px"
                        }}>
                        </div>
                        <div>
                            <span>{t('instockthispartner')}</span>
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={{
                            display: "flex",
                            width: "20px",
                            height: "20px",
                            border: "solid 2px #048b9a",
                            margin: "auto",
                            marginBottom: "5px", opacity: "0.4",
                            borderRadius: "5px"
                        }}>
                        </div>
                        <div>
                            <span>En stock dans un autre coloris</span>
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={{
                            display: "flex",
                            width: "20px",
                            height: "20px",
                            border: "solid 2px grey",
                            margin: "auto",
                            marginBottom: "5px",
                            opacity: "0.3",
                            borderRadius: "5px"
                        }}>
                        </div>
                        <div>
                            <span>{t('nostock')}</span>
                        </div>
                    </div>
                </div> : null
            }

            {selectedProductDeclination.bestRetailer.url && unavailableSizes && unavailableSizes.length > 0 ?
                <div style={{ textAlign: 'center', marginTop: '10px'}}>

                    <p><i className="fa-regular fa-face-frown" style={{color: 'red'}}></i>&nbsp;
                        {t('nostockinyoursize')} &nbsp;<span style={{cursor: "pointer"}} onClick={() => setModalShow(true)}><u>{t('receiveanotification')}</u></span></p>
                </div>
                : null }

            <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>

            <MobileView>
            {
                product && product.headCategory == "/products/shoes" ?
                    <>
                        <div>
                            <Row>
                                <Col xs={isMobileOnly ? 6 : 3}>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Drop :</b> {product.drop && product.drop !== "" ? product.drop + "mm" : "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Stack :</b> {product.stack && product.stack !== "" ? product.stack: "N/A"}</span>
                                    </div>
                                </Col>
                                <Col xs={isMobileOnly ? 6 : 4}>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Poids :</b> {product.weigh && product.weigh !== "" ? product.weigh + "g" : "N/A"}</span>
                                    </div>
                                    <div className="pro-details-meta">
                                        <span><b style={{fontSize: "16px", color : "black"}}>Type d'amorti :</b> {product.cushioningType && product.cushioningType !== "" ? product.cushioningType : "N/A"} {product.isCarbonPlate == "true" ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Avec plaque carbone</Tooltip>}><TbBrandCarbon size={"1rem"}/></OverlayTrigger> : null}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="pro-details-list" style={{marginBottom: '10px', marginTop: '10px'}}></div>
                    </>
                : null
            }   
            </MobileView>


            <div className="pro-details-meta">
                <span>{t('reference')} {selectedProductDeclination.reference}</span>
            </div>


            {productRating && ratingProductStars > 0 ?
                <div className="pro-details-meta">
                    <span id="review-rating-stars">{t('clientrate')} ({productRating.length}) : &nbsp;
                        {(() => {
                            const rows = [];

                            for (let i = 0; i < Math.round(ratingProductStars); i++) {
                                rows.push(<i className="fa fa-star" key={i} style={{color: '#ffa900'}}/>);
                            }
                            if(Math.round(ratingProductStars) < 5) {
                                for (let i = 0; i < Math.round(5-ratingProductStars); i++) {
                                    rows.push(<i className="fa fa-star" key={i} style={{color: 'grey'}}/>);
                                }
                            }
                            return rows;
                        })()} &nbsp; {ratingProductStars.toFixed(2)}/5
                    </span>
                </div>
                :
                <div className="pro-details-meta">
                    <span style={{cursor: 'pointer'}} onClick={isMobileOnly ? scroll : scrollDesktop}><b>{t('norate')}</b></span>
                </div>
            }

            <div className="pro-details-meta" style={{display: "none"}}>
                <span>{t('lastupdate')} {product.updatedDate}</span>
            </div>
            <div id="othersSizesDesktop"></div>


            {/* product modal */}
            <NewsletterProductModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                product={product}
                unavailableSizes={unavailableSizes}
                selectedProductDeclination={selectedProductDeclination}
                addtocart={addToCart}
                addtowishlist={addToWishlist}
                addtocompare={addToCompare}
            />

            {/* product modal */}
            <NewsletterPriceModal
                show={modalPriceShow}
                onHide={() => setModalPriceShow(false)}
                product={product}
                unavailableSizes={unavailableSizes}
                selectedProductDeclination={selectedProductDeclination}
                addtocart={addToCart}
                addtowishlist={addToWishlist}
                addtocompare={addToCompare}
            />

            {
                isAmbassador && openExportAmbassadorProduct ?
                    <GenerateAmbassadorLinkModal open={openExportAmbassadorProduct} setOpen={setOpenExportAmbassadorProduct} currentLink={window.location.href}/>
                : null
            }
        </div>
    );
};

ProductDescriptionInfo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  addToast: PropTypes.func,
  cartItems: PropTypes.array,
  compareItem: PropTypes.array,
  currency: PropTypes.object,
  currentDeclination: PropTypes.object,
  discountedPrice: PropTypes.number,
  finalDiscountedPrice: PropTypes.number,
  finalProductPrice: PropTypes.number,
  product: PropTypes.object,
  wishlistItem: PropTypes.object,
  setCurrentDeclination: PropTypes.func,
    othersSizesRef: PropTypes.any,
    productRating: PropTypes.array,
    setCurrentTab: PropTypes.func,
    averageHistory: PropTypes.any,
    setSelectedUserSize: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    }
  };
};

function getHexaColorFromName(colorName, colors){
  if (colors && colors.length > 0) {
    let hexaColor = colors.filter(color => { return color.name === colorName})[0].hexaColor
    return rgbToHex(hexaColor.r, hexaColor.g, hexaColor.b)
  }
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}


export function setCookie(cName, cValue, expHours) {
    let date = new Date();
    date.setTime(date.getTime() + (expHours * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

export function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

export default connect(null, mapDispatchToProps)(ProductDescriptionInfo);
