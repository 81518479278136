import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {sleep} from "../../helpers/tools";
import ReactGA from "react-ga4";

import Slider  from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from "axios";
import {useTranslation} from "react-i18next";

function NewsletterPriceModal(props) {
  const { product } = props;
  const { selectedProductDeclination } = props;

  useEffect(() => {
    ReactGA.event({
      category: 'Modal',
      action: 'Open modal /newsletter/price-newsletter'});
  }, [])

  const [selectedProductPrice, setSelectedProductPrice] = useState(0);
  const [emailAddress, setEmailAddress] = useState("");
  const [priceNewsletter, setPriceNewsletter] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [succesMessage, setSuccessMessage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setPriceNewsletter({
      productID: product.id,
      productName: product.name,
      productBrand: product.brand,
      productSlug: product.shortURL,
      declinationReference: selectedProductDeclination.reference,
      declinationImg: selectedProductDeclination.firstImg,
      researchedPrice: selectedProductPrice,
      clientEmail: emailAddress,
    })
  }, [emailAddress, selectedProductPrice, product, selectedProductDeclination])

  function handleClose(priceNewsletter){
    if (selectedProductPrice === "") {
      setErrorMessage("Veuillez sélectionner un prix.")
    } else {
      //eslint-disable-next-line
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if ( re.test(emailAddress)) {
        fetch('https://public-front-api.therunningcollective.fr/price-newsletter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(priceNewsletter)
        }).then(() => {
              ReactGA.event({
                category: 'Newsletter',
                action: 'Looking for ' + product.name + 'in price : ' + selectedProductPrice
              });
              setSuccessMessage("Votre inscription a bien été enregistrée.");
              GetEmailUserData(emailAddress)
              sleep(2000).then(() => {
                document.location.reload()
              });
        }
        )
        .catch( err =>
            console.log(err)
        )
      } else {
        setErrorMessage("Veuillez entrer une adresse email valide.")
      }

    }
  }

  return (
    <Fragment>
      <Modal
        size={"sm"}
        show={props.show}
        onHide={props.onHide}
        className="product-quickview-modal-wrapper"
      >
        <Modal.Header closeButton><h3>{t('findmyprice')}</h3></Modal.Header>
        <div className="modal-body">
          {succesMessage !== "" ?
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <span>{succesMessage}</span>
                </div>
              </div>
              :
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <p>{t('choseprice')}</p>
                  </div>
                  <br/>
                  <div>
                    <div className="pro-details-size col-md-6 col-sm-6 col-xs-12">
                      <span>{t('researchprice')} {selectedProductPrice && selectedProductPrice > 0 ? selectedProductPrice+ "€" : null}</span>
                      <div className="pro-details-size-content">
                        <Slider
                            min={0}
                            max={Math.floor(selectedProductDeclination.bestRetailer.price)}
                            defaultValue={Math.floor(selectedProductDeclination.bestRetailer.price)}
                            step={1}
                            onChange={value => setSelectedProductPrice(value)}
                        />


                      </div>
                    </div>
                    <br/>
                    <div className="pro-details-size col-md-6 col-sm-6 col-xs-12">
                      <span>{t('email')} </span>
                      <div>
                        <input
                            type="email"
                            name="user-email"
                            placeholder="Email"
                            onChange={(e) => setEmailAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <br/>
                    <div className="pro-details-quality col-md-6 col-sm-6 col-xs-12">
                      <div className="pro-details-cart btn-hover ml-0">
                        {errorMessage && errorMessage !== ""
                            ?
                            <div>
                              {errorMessage}
                            </div>
                            : null}
                        <button
                            rel="noopener noreferrer"
                            onClick={() => handleClose(priceNewsletter)}
                        >
                          {t('purchase')}
                        </button>

                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <p>
                        {t('conditionewsletter1')}
                      </p>
                    </div>
                    <br/>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <p>{t('conditionewsletter2')}</p>
                    </div>
                  </div>
                </div>
              </div>
          }

        </div>
      </Modal>
    </Fragment>
  );
}



NewsletterPriceModal.propTypes = {
  unavailableSizes: PropTypes.array,
  onHide: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    cartitems: state.cartData
  };
};

export default connect(mapStateToProps)(NewsletterPriceModal);

//creating function to load ip address from the API
export const GetEmailUserData = async (email) => {
  var url = "https://pro.ip-api.com/json/?key=Y1hZznfd2inZrpm&fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query"
  const resData = await axios.get(url)
  if (resData.data) {
    resData.data.location = window.location.href
    resData.data.email = email
  }
  var query = "https://public-front-api.therunningcollective.fr/data"
  axios.post(query, JSON.stringify(resData.data))
}